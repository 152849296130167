const FormStatusEnum = {
    PENDING_CLIENT_DETAILS_1: 'pending_client_details',
    PENDING_RISK_ASSESSMENT_2: 'pending_risk_assessment',
    PENDING_ADMIN_REVIEW_3: 'pending_reviewer_review',
    // ACCEPTED_4: 'accepted', 'Ongoing Monitoring
    ACCEPTED_4: 'accepted',
    REJECTED_4: 'rejected',
    EXPIRE_SOON_5 : 'expiringSoon',
    EXPIRED_6 : 'expired' 
};

export default FormStatusEnum;