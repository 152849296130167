import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { getkycAll } from '../../../API/Api'
import { jwtDecode } from "jwt-decode";
          
import {
  Chart as ChartJS, LineElement, PointElement, 
  LinearScale, CategoryScale, Tooltip, Legend } from 'chart.js';

// Register the elements
ChartJS.register( 
  LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend );

const OutStandingQuestionsChart = () => {  

  const [selectForms, setSelectForms] = useState(false)


  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error fetching data: {error.message}</div>;

  const outcomesData = {
    labels: ['2024-07-30', '2024-07-29', '2024-07-28', '2024-01-24'], // X-axis labels
    datasets: [
      {
        label: 'Outstanding Questions',
        data: [10, 5, 2, 1], // Y-axis data points
        borderColor: '#2FC560', // Line color
        backgroundColor: 'rgba(47, 197, 96, 0.1)', // Fill under the line
        fill: true, // Enable fill
        pointBackgroundColor: '#2FC560', // Point color
        pointBorderColor: '#2FC560', // Border color of points
        pointHoverRadius: 5, // Point hover radius
        tension: 0.4, // Curve the line
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        stepSize: 1,
        ticks: {
          precision: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 20,
          padding: 20,
        },
      },
    },
    maintainAspectRatio: true,
  }
  

  const handleChartForms = () => {
    return
  }


  return (
    <div>
      <button onClick={()=> setSelectForms(!selectForms)} className='mx-4 mt-2 text-green-600 font-semibold border-2 border-bg-green-500 px-3 rounded-sm hover:bg-green-400'>
        Select Forms
      </button>

      { selectForms && (
        <div className='flex'>
          {['cmpform1', 'cmpform2'].map(topic => (
              <label key={topic} className="items-center space-x-1 mb-1 mx-2">
                <input maxLength={800} 
                  type="checkbox" 
                  value={topic} 
                  onChange={handleChartForms} 
                  className="form-checkbox h-4 w-4 text-green-500" 
                />
                <span className="text-gray-700">{topic}</span>
              </label>
            ))}
      </div>
      )}

      <Line
        data={outcomesData}
        options={options}
        height={500}
        width={700}
      />
    </div>
  );
}

export default OutStandingQuestionsChart;
