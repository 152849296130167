import React, { useContext } from 'react';
import { createUser,  updateUser } from '../../API/Api';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { TokenContext } from '../../App';

const AddEditCompanyModal = ({user, handleCloseModal, allUsers, action, setLoading}) => {
  //   const [encodedAccessToken] = useContext(TokenContext) 
  //   const decodedToken = jwtDecode(encodedAccessToken)
 

  const { register, handleSubmit, watch, reset , formState: {errors, isSubmitting } } = useForm({
    // defaultValues: { 
    //   name: user?.name, email: user?.email,  companyId:  decodedToken.companyId, companyName: decodedToken.companyName, isActive: user?.isActive || true, role: user?.role || 'admin',
    //   subscriptions: {
    //     cmp: user?.subscriptions?.cmp || false,
    //     fatca: user?.subscriptions?.fatca || false,
    //     fit_proper: user?.subscriptions?.fit_proper || false,
    //     kyc_aml: user?.subscriptions?.kyc_aml || true 
    //   }},
  });
  
  const onSubmit = async (userData) => {
    // console.log(userData)
  //   userData = {
  //     ...userData,
  //     email: userData.email.trim(),
  //     inviter: decodedToken.name
  //   }

  //   try { 
  //     setLoading(true);
  //     handleCloseModal();

  //   } catch (error) { 
  //       toast.error('Something went wrong...');
  //     }
    }

  return (
    <div className="sm:w-[60vw] md:w-[45vw] lg:w-[40vw] xl:w-[30vw] p-5">  {/* //-> viewport for different breakpoints */}
      <p className='font-semibold underline mb-2'> Subscriptions </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mt-2'>
            <input maxLength={800} type="checkbox" {...register('subscriptions.kyc_aml')} className="w-4 h-4 mx-1"/> 
            <label className="text-sm font-medium text-gray-700 mt-3"> KYC/AML Portal </label> <br/>

            <input maxLength={800} type="checkbox" {...register('subscriptions.cmp')}  className="w-4 h-4 mx-1 inline"/> 
            <label className="text-sm font-medium text-gray-700 mt-3"> Compliance Monitoring Program </label> <br/>
        </div>

        <div className='flex justify-end w-full mb-2'>
          <button type="submit" disabled={isSubmitting} className={`bg-green-500 mx-4 mt-2 text-white font-semibold px-3 rounded-sm ${isSubmitting ? 'opacity-50 cursor-not-allowed': 'hover:bg-green-600' }`}>
            {isSubmitting ? 'Submitting...' : `${action} Company`}
          </button>

          <p onClick={handleCloseModal} className='cursor-pointer bg-gray-400 mt-3 text-white font-semibold py-2 px-4 rounded-sm hover:bg-gray-500'>
            Cancel 
          </p>
        </div>
      </form>
    </div>
  );
};

export default AddEditCompanyModal;


   