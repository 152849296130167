import React from 'react'
import { getAllNotifications } from '../../../API/Api'
import { jwtDecode } from "jwt-decode";
import { DateTime } from 'luxon';

export const KycAmlNotifications = () => {


  return (
        <div>
          Notifications
        </div>
  )
}

export default KycAmlNotifications