import React, { useState, useEffect } from 'react';
import StopIcon from '@mui/icons-material/Stop';
import { RightContainer, Row } from "../shared/utils"
import AddEditCompanyModal from "./AddEditCompanyModal";
import Modal from "../shared/Modal";
import Tooltip from '@mui/material/Tooltip'; 
import { getAllCompanyKycDetails } from '../../API/Api'
import { DateTime } from 'luxon';



const SuperAdminAllKycBilling = () => {
    const [allCompaniesKycBilling, setAllCompaniesKycBilling] = useState([]);  
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      getAllCompanyKycDetails()
        .then(({ data }) => {
          setAllCompaniesKycBilling(data);
          setLoading(false);
          console.log(data)
          console.log('data')
        })
        .catch(e => console.error("Error fetching all companies:", e));
    }, []);


    const getCompanyStatusTag = (status='active') => { 
        if (status == 'active') {
            return <span className="bg-green-400 text-black rounded-full px-2 py-1 text-xs font-semibold"> Active </span>  
        }
        else if (status == 'inactive') {
            return <span className="bg-red-400 text-black rounded-full px-2 py-1 text-xs font-semibold"> Disabled </span>  
        } 
      }  
  
      
      if (loading) {
        return <div> Loading... </div>
      }

  return (
    <div>
      <Row>  
        <div className="w-full flex justify-between items-center p-4 bg-white shadow">
          <strong className='text-xl'> Companies </strong> 
        </div>
      </Row> 

      <div>
          <div>
            <table className="w-full border-collapse border border-gray-300 shadow table-auto">
              <thead>
                <tr className="bg-gray-300 text-left">
                  <th className="p-2"> Company Name</th>
                  <th className="p-2"> Credits left / alert limit </th>
                  <th className="p-2"> Type </th>   {/* eg   name screen only */}
                  <th className="p-2"> Rescreening Interval <br/> (Standard / High Risk) </th>
                  {/* <th className="p-2 text-center"> Actions </th> */}
                </tr>
              </thead>
              <tbody>
                {allCompaniesKycBilling.map((company, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-green-50 hover:bg-green-100 border' : 'bg-white hover:bg-green-100 border'}>
                  <td className="p-2 border">{company.companyId}</td>
                  <td className="p-2 border"> {company.currentCredits} / {company.creditWarning} </td>
                  <td className="p-2 border"> {company.companyScreeningType} </td>
                    <td className="p-2 w-1/12  border text-center">
                        {company.rescreenHighRiskInterval} / {company.rescreenStandardRiskInterval} days
                    </td>
                    {/* <td className="p-2 w-1/12 border">
                      <div className="flex">
                        <Tooltip title={"Edit Subscriptions (Not enabled yet)"} className='px-1.5 pt-1 mx-1  bg-green-400 cursor-pointer hover:bg-green-500  transition duration-500' arrow>  
                            <div>
                               <Modal ModalTitle="Edit Company" CustomButton={()=> <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clip-rule="evenodd" d="M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z" fill="#000000"/> </svg>}>
                                <AddEditCompanyModal setLoading={setLoading} action='Edit' companyDetails={company}/>
                              </Modal> 
                            </div> 
                        </Tooltip>  

                        <Tooltip title={"Disable"} className='px-1 pt-1.5 mx-1 bg-red-400 cursor-pointer hover:bg-red-500 transition duration-500' arrow>  
                            <div>
                              {/* <Modal ModalTitle="Disable Company" CustomButton={()=> <StopIcon sx={{ fontSize: 16, mb: 1 }} /> } >
                                <div className='px-9 py-6' setLoading={setLoading} > 
                                  <p className='mb-5'> Are you sure you want to disable this company? (Not enabled yet) </p>
                                  <div className='flex justify-between'>
                                    <button className='px-6 py-2 mr-4 border bg-red-500 hover:bg-red-600 text-white'> Delete </button>
                                    <button className='px-6 py-2 mx-6 border bg-slate-300'> Cancel </button>
                                  </div>
                                </div>
                              </Modal> 
                            </div> 
                        </Tooltip>  
                     
                      </div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      <br/> <br/> <br/> 
    </div>
  );
};

export default SuperAdminAllKycBilling