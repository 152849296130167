import React, { useState, useEffect, useContext } from 'react';
import { uploadFile, updatekyc ,updateKycRescreeningDate, sanctionIoHits, createNotification, createKycComment, getkycRiskAssessment, worldCheckHits, createKycNestedSanctionComment, sendEmailNotification, getkyc, getkycAll, updatekycStatus,  createkycRiskAssessment, updatekycRiskAssessment, getAllUsers, frontEndBaseURL} from "../../../API/Api" 
import { Card } from '../../shared/utils';
import QuestionCard_ACRA from './QuestionCard_ACRA';  
import { DateTime } from "luxon"
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import SanctionsScreeningSection from './SanctionsScreeningSection'
import SubmissionCard from './SubmissionCard';
import { jwtDecode } from "jwt-decode";
import { TokenContext } from '../../../App';

const _RiskAssessment_ACRA = ({ clientFormId, userType, readOnly, currentUrl, formAuthority}) => {
  const [encodedAccessToken] = useContext(TokenContext) 
  const decodedToken = jwtDecode(encodedAccessToken)

  const [formData, setFormData] = useState({
    kycaml_client_id: clientFormId, formAuthority: formAuthority, screenedDate: '', submittedBy: '', reviewerEmail: [],
    sourceOfWealthDocumentURL: '', otherDocumentsURL: '', managementSupportDocumentURL: '',
    riskLevel: '', screenedDate: '', nextRescreeningDate: '', status: 'new',  
    // riskLevel: 'Incomplete questions!', screenedDate: '', nextRescreeningDate: '', status: 'new',  
    formingOfCorporations: { response: '', comments: [] },   // comment object [ {name: '', content: '', time: '', id: ''} ]
    pepHits:[], crimesHits:[], sanctionsHits:[], highRiskJurisdictionHits:[], adverseNewsHits:[], screeningSource: 'sanctions.io'
  }); 

  const [kycData, setKycData] = useState(); 
  const [loading, setLoading] = useState(true);
  const [allreviewers, setAllreviewers] = useState([])
  const userRole = decodedToken.role
  let allQuestionsAnswered = true
  
  useEffect(() => {
    const fetchData = async () => {

      try {
        let allreviewers = (await getAllUsers()).data
        allreviewers = allreviewers.filter(users => users.role !== 'preparer' && users.role !== 'viewer');
        setAllreviewers(allreviewers) 

        const currentKycData = await getkyc(clientFormId).then(response => response.data) 
        setKycData(currentKycData)

        const kycRiskDataList = await getkycRiskAssessment(clientFormId).then(response => response.data);  
        let kycRiskData = kycRiskDataList[0]  

        if (!kycRiskDataList.length == 0) { 
          setFormData(kycRiskData)
          setLoading(false);
          return
        }

         
        toast.info(`Assessment running...`);
        // 1. Run automated checks and create entry
        const emptyFormData =({ kycaml_client_id: clientFormId, riskLevel: '', status: 'new', pepHits:[], crimesHits:[], sanctionsHits:[], highRiskJurisdictionHits:[], adverseNewsHits:[], screeningSource: 'sanctions.io'});
        // const emptyFormData =({ kycaml_client_id: clientFormId, riskLevel: 'Incomplete questions!', status: 'new', pepHits:[], crimesHits:[], sanctionsHits:[], highRiskJurisdictionHits:[], adverseNewsHits:[], screeningSource: 'sanctions.io'});

        // const emptyFormData = automatedChecks(clientFormId, currentKycData)
        const creationDate = DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss")  
        kycRiskData = {
          ...emptyFormData, 
          screenedDate: creationDate         
        } 
        await createkycRiskAssessment(kycRiskData) 


        // 2. Call sanctions screening API and update entry 
        // Only these companies use worldCheck by default screening
        if ( ['500_Global'].includes(decodedToken.companyId) ) {
          const payload = { 
            // apiKey, apiSecret, 
            screenedName:currentKycData['name'],
            connectedParty:currentKycData?.details?.connectedParty,
            entityType:currentKycData['type'],
            kycaml_client_id : currentKycData['_id'],
            screenedDate : creationDate,
            screeningSource: 'worldCheck'
           }
          
          const worldCheckResponse = await worldCheckHits(payload)

          if (worldCheckResponse.data.message == 'ERR_BAD_REQUEST') { 
            toast.error('Invalid worldCheck API key/secret!') 
            return
          }
          else {
            const {pepHits, crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits} = worldCheckResponse.data
            setFormData({...formData, pepHits,crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits, screeningSource: 'worldCheck'})
            checkRiskStatus({...kycRiskData, pepHits, crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits })
          }

        }
        // Sanctions.io API screening by default
        else {
          const payload = { 
            screenedName:currentKycData['name'],
            connectedParty:currentKycData?.details?.connectedParty,
            entityType:currentKycData['type'],
            kycaml_client_id : currentKycData['_id'],
            screenedDate : creationDate,
            screeningSource: 'sanctions.io' 
          }
          const {pepHits, crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits} = (await sanctionIoHits(payload)).data
          const updatedData = {...kycRiskData, pepHits, crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits, screeningSource: 'sanctions.io'}
          setFormData(updatedData)
          checkRiskStatus(updatedData)
        }

        toast.success(`Assessment complete!`);
        setLoading(false);
    
      } catch(e) {
        console.error(e)
        toast.error(`Something went wrong...`);
      } 
    } 
  
    fetchData()
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newFormData = {   
      ...formData,          
      [name] : value  
    }

    setFormData(newFormData) 
    checkRiskStatus(newFormData)
  }

  const handleNestedInputChange = (e, nestedName) => {
    const { name, value } = e.target; 

    const newFormData = {   
      ...formData,         
      [name] : {
        ...formData[name],
        [nestedName] : value
      }  
    }
    setFormData(newFormData) 
    checkRiskStatus(newFormData)
  }

  const handleRunAssessmentAgain = async (e) => {
    try {
      setLoading(true)
      toast.info(`Assessment running...`);

      const currentKycData = await getkyc(clientFormId).then(response => response.data) 
      setKycData(currentKycData)
      
      // 1. Run automated checks and create entry
      // The default should be Incomplete questions, but temp switch off due to bugs
      // const emptyFormData =({ kycaml_client_id: clientFormId, riskLevel: 'Incomplete questions!', status: 'new', pepHits:[], crimesHits:[], sanctionsHits:[], highRiskJurisdictionHits:[], adverseNewsHits:[], screeningSource: 'sanctions.io'});
      const emptyFormData =({ kycaml_client_id: clientFormId, riskLevel: '', status: 'new', pepHits:[], crimesHits:[], sanctionsHits:[], highRiskJurisdictionHits:[], adverseNewsHits:[], screeningSource: 'sanctions.io'});
      const currentScreenedDate = formData.screenedDate;
      const creationDate = DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss")  
      const kycRiskData = {
        ...emptyFormData, 
        screenedDate: creationDate,
        currentScreenedDate       
      } 
      await updatekycRiskAssessment(kycRiskData)

      // 2. Call sanctions.io API and update entry 
      if ( ['500_Global'].includes(decodedToken.companyId) ) {
        const payload = { 
          // apiKey, apiSecret, 
          screenedName:currentKycData['name'],
          connectedParty:currentKycData?.details?.connectedParty,
          entityType:currentKycData['type'],
          kycaml_client_id : currentKycData['_id'],
          screenedDate : creationDate,
          screeningSource: 'worldCheck'
         }
        
        const worldCheckResponse = await worldCheckHits(payload)

        if (worldCheckResponse.data.message == 'ERR_BAD_REQUEST') { 
          toast.error('Invalid worldCheck API key/secret!') 
          return
        }
        else {
          const {pepHits, crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits} = worldCheckResponse.data
          setFormData({...formData, pepHits,crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits, screeningSource: 'worldCheck'})
          checkRiskStatus({...kycRiskData, pepHits, crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits })
        }
      }
      else {
        const payload = { 
          screenedName:currentKycData['name'],
          connectedParty:currentKycData?.details?.connectedParty,
          entityType:currentKycData['type'],
          kycaml_client_id : currentKycData['_id'],
          screenedDate : creationDate,
          screeningSource: 'sanctions.io' 
        }
        const {pepHits, crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits} = (await sanctionIoHits(payload)).data
        const updatedData = {...kycRiskData, pepHits,crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits, screeningSource: 'sanctions.io'}
        setFormData(updatedData)
        checkRiskStatus(updatedData)
      }
      
      toast.success(`Assessment complete!`);
      setLoading(false);
     
    } catch(e) {
      console.error(e)
      toast.error(`Something went wrong...`);
    }

  }


  const handleSubmitComment = async (e, nestedName, inputName) => {
    if (formData[inputName][nestedName].trim() == '') { return }

    const newCommentEntry = {
      screenedDate: formData.screenedDate,
      kycaml_client_id: clientFormId,
      question: inputName,
      content : formData[inputName][nestedName],
      name : decodedToken.name,
      commented_at : DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
      comment_id : uuidv4() //random ID, for future if allow deletion
    }
    await createKycComment(newCommentEntry)  
    formData[inputName].comments.push(newCommentEntry);

    setFormData((prevState) => ({
      ...prevState,
      [inputName]: {
          ...prevState[inputName],  // Keep existing nested object properties
          [nestedName]: formData[inputName].comments,     // .push should be on its own satement, not assignment
          // [nestedName]: [...formData[inputName].comments, newCommentEntry],   // push mutates the array, so runs double
          newComment: ''   
      }
  }));

  }

  const handleSaveForm = async (e) => {  
    await updatekycRiskAssessment(formData) 
    toast.success(`Assessment saved!`);
  }   
 

  const handleSubmitForReview = async (e) => { 
    let newFormData = {
      ...formData,
      status: 'waiting for review', 
      submitPerson : decodedToken.name,
      submitDate : DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
    }

    // submittedBy
    await updatekycRiskAssessment(newFormData)
    updatekycStatus({formStatus: "pending_reviewer_review", id: clientFormId })
    toast.success(`Submitted for review!`);
    
    sendEmailNotification({
      email: formData.reviewerReviewEmail,
      subject: "KYC Risk Assessment Form pending review - iCompass",
      message: `
        <div style="max-width: 600px; margin: 0 auto; border: 2px solid #FF8C00; padding: 20px; text-align: center;">
          <h1 style="margin-bottom: 20px; text-decoration: underline;"> Risk Assessment Approval Pending</h1>
          <p>${decodedToken.name} has submitted a risk assessment form, ${kycData.name} (${kycData.type}), for your approval. 
            <a href='${window.location.href}'> Click to view form </a>
          </p>
          <img src="https://icompass-backend-dev-fhagh5hccpf2ebh9.southeastasia-01.azurewebsites.net/images/from_icompass" alt="iCOMPASS logo" style="display: block; margin: 20px auto; width: 200px; height: auto; max-width: 100%;">
          <p style="font-style: italic; margin-top: 20px;">Please do not reply to this email</p>
        </div> `
    })

    setFormData(newFormData)
    
    createNotification({
      type: 'KYC',
      text: `${kycData.name} status has changed to Pending reviewer Review`,
      date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
  })
  }  


  const handleFileChange = async (e, fileType) => {
    const file = e.target.files[0];
    const {name} = e.target
    if (!file) { return }

    try {
      const data = new FormData();
      data.append("file", file);
      const filename = `${file.name}`;
      const res = await uploadFile(data, filename); 

      if (res) { 
        setFormData(prevState => ({
          ...prevState,
          [name] : res.data.url
      }))

      } else {
        toast.error("Invalid file type!") 
      }



    } catch (e) {
      toast.error(`Invalid file type!`);
    }
  }

  const checkRiskStatus = (newFormData) => { 
    // 1. If section A count...
    const SectionA_Questions = ['formingOfCorporations', 'actingAsDirectorSecretaryPartner', 'actingAsShareholder', 'providingRegisteredOffice', 'buyingSellingRealEstate', 'managingClientAssets', 'managingBankAccounts', 'organizingCompanyContributions', 'buyingSellingBusinessEntities', 'statutoryAuditServices', 'providingOtherServices' ]
    let SectionA_yes_count = 0 

    SectionA_Questions.forEach(SectionA_qn_key => {      
      if (newFormData[SectionA_qn_key]?.response == 'Yes' ) { SectionA_yes_count++ }   
    })  
    
    // 2. If section B count...
    const SectionB_Questions = [ 'newClient', 'cashIntensiveBusiness', 'frequentUnaccountedCashTransactions', 'politicallyExposedPerson', 'outdatedCompanyAccounts', 'problemObtainingInfo', 'infoNotIndependentlyVerified', 'highRiskIndustry', 'isPublicCompanyNoDisclosure', 'isLegalPersonOrEntity', 'usesNomineeDirectorsShareholders', 'nomineeShareholdersMajorityOwnership', 'unusualComplexOwnershipStructure', 'directorsCriminalConvictions', 'frequentDirectorChanges', 'unregisteredNonProfitOrganisation', 'shellCompany', 'hasAdverseScreeningResults', 'hasUnsatisfactoryExceptions',
       'pepHits....', '', '', '', '', '', '' ]
    let SectionB_yes_count = 0 

    SectionB_Questions.forEach(SectionB_qn_key => {       
      if (newFormData[SectionB_qn_key]?.response == 'Yes') { SectionB_yes_count++ }
    })

    // 2b. If section B count...
    const EDD_Hits_Questions = ['pepHits', 'crimesHits', 'sanctionsHits', 'highRiskJurisdictionHits', 'adverseNewsHits']
    // const EDD_Questions = [ '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '' ]
    let EDD_Hits_yes_count_Justified = 0
    let EDD_Hits_yes_count_NotJustified = 0
    
    EDD_Hits_Questions.forEach(EDD_qn_justification_key => {
      newFormData[EDD_qn_justification_key].forEach(hit => {
        if (hit?.response === 'Yes' && hit?.justification.trim() == '') { 
          EDD_Hits_yes_count_NotJustified++ 
        } else if (hit?.response === 'Yes' && hit?.justification.trim() !== '') { 
          EDD_Hits_yes_count_Justified++ 
        }
      })
    })

    // 3. If section C count...
    const SectionC_Questions = [ 'connectedToHighRiskAmlCountry', 'connectedToHighRiskJurisdiction', 'connectedToSanctionedCountry', 'connectedToTerroristFundingCountry', 'connectedToFATFCountry' ]
    let SectionC_yes_count = 0
 
    SectionC_Questions.forEach(SectionC_qn_key => {
      if (newFormData[SectionC_qn_key]?.response == 'Yes') { SectionC_yes_count++ } 
    })

    // 4. If section D count...
    const SectionD_Questions = [ 'anonymousTransactionInstructions', 'fundsTransferWithoutUnderlyingService', 'unusualTransactionPatterns', 'unaccountedPaymentsFromUnknownParties', 'instructionForShellCompanyWithNominees', 'purchaseOfEntitiesWithoutCommercialPurpose', 'relationshipWithoutPhysicalMeeting', 'transactionsWithoutPhysicalMeeting', 'inconsistentWithClientRiskProfile']
    let SectionD_yes_count = 0

    SectionD_Questions.forEach(SectionD_qn_key => { 
      if (newFormData[SectionD_qn_key]?.response == 'Yes') { SectionD_yes_count++ }
    }) 

    // 5. Check if all questions have been answered
    let individualQuestions = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']
    const entityQuestions = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']
    if (userType == 'entity') { individualQuestions = [...individualQuestions, ...entityQuestions] }

    const allQuestionsAnswered = individualQuestions.every((question_key) => {
      const value = newFormData[question_key];
      if (typeof value === 'object') {
        return value?.response?.trim() // If value is an object of: {response: '', justification: '', newComment: '', comments: []} , check if 'response' exists and is non-empty
      }
      return typeof value === 'string' && value.trim();  
    });

    // 6. Determine risk level
    let riskLevel = ''
    // let riskLevel = 'Incomplete Questions!'
    if (!allQuestionsAnswered) {
      console.log('Not all questions answered')
    }
    else if (SectionB_yes_count < 5 ||  SectionC_yes_count < 4 ||  SectionD_yes_count < 4 ) {
        riskLevel = 'Standard'
      } 
    else if ( SectionB_yes_count > 5 ||  SectionC_yes_count > 4 ||  SectionD_yes_count > 4 ) {
        riskLevel = 'High';
    }  
    else {
      riskLevel = "edge case!"
    }
    
    const updatedFormData = {
      ...newFormData,
      riskLevel : riskLevel  
    }
    setFormData(updatedFormData)
  }

  const getRiskTag = (risk) => {
    const riskClasses = {
      Low: 'text-green-500',
      Standard: 'text-orange-500',
      High: 'text-red-500',
      Edge: 'text-purple-600', 
    };
    const riskClass = riskClasses[risk] || riskClasses['Edge']; // Default to 'Edge' for unexpected edge cases
    return <span className={`bg-gray-200 py-2 px-4 border border-b-gray-300 ${riskClass}`}>{risk}</span>;
  };

  const getStatusTag = (status) => {
    if (status == "new" || status == "waiting for review") {
      return <span className="text-orange-500"> Yet to be decided by reviewer</span> 
    }
    else if (status == 'accepted') {
      return <span className="text-green-500">Accepted</span> 
    }
    else if (status == 'rejected') {
      return <span className="text-red-500">Rejected</span> 
    } else {
      return <span className="text-purple-600">Edge case!!!</span> 
    }
  }


  const handleStatusUpdate = async (e, updatedStatus) => {

      let nextRescreeningDate = ''
      let onGoingMonitoring=''
      let currentDate = DateTime.now().setZone('Asia/Singapore');
      let maxPage = 2

      if (updatedStatus == 'accepted') {
        if (formData.riskLevel == 'Low') {  nextRescreeningDate = currentDate.plus({ years: 3 }).toFormat("dd-MM-yyyy") } 
        if (formData.riskLevel == 'Standard') {  nextRescreeningDate = currentDate.plus({ years: 2 }).toFormat("dd-MM-yyyy") } 
        if (formData.riskLevel == 'High') {  nextRescreeningDate = currentDate.plus({ years: 1 }).toFormat("dd-MM-yyyy") } 
        if (decodedToken.companyId == 'Inspro_Insurance') {  nextRescreeningDate = currentDate.plus({ years: 1 }).toFormat("dd-MM-yyyy") } 
        onGoingMonitoring = true
        maxPage = 3
      }

      const currentScreenedDate = formData.screenedDate;
      const newFormData = {
          ...formData,
          status : updatedStatus,
          screenedDate :  DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
          nextRescreeningDate: nextRescreeningDate,
          approvalPerson: decodedToken.name,
          approvalDate :  DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
          currentScreenedDate
        }

      setFormData(newFormData)
      updatekycStatus({
        formStatus: updatedStatus,
        id: clientFormId,
        nextRescreeningDate: nextRescreeningDate,
        onGoingMonitoring: onGoingMonitoring,
        maxPage: maxPage
       })

      await updatekycRiskAssessment(newFormData)


      sendEmailNotification({
        email: kycData.email,
        subject: "KYC Risk Assessment Form status update - iCompass",
        message: `
          <div style="max-width: 600px; margin: 0 auto; border: 2px solid #FF8C00; padding: 20px; text-align: center;">
            <h1 style="margin-bottom: 20px; text-decoration: underline;"> Risk Assessment Status update</h1>
            <p>${decodedToken.name} has updated the status of risk assessment form for, ${kycData.name} (${kycData.type}) to ${updatedStatus}. 
              <a href='${window.location.href}'> Click to view form </a>
            </p>
            <img src="https://icompass-backend-dev-fhagh5hccpf2ebh9.southeastasia-01.azurewebsites.net/images/from_icompass" alt="iCOMPASS logo" style="display: block; margin: 20px auto; width: 200px; height: auto; max-width: 100%;">
            <p style="font-style: italic; margin-top: 20px;">Please do not reply to this email</p>
          </div> `
      })

      createNotification({
        type: 'KYC',
        text: `${kycData.name} status has changed to ${updatedStatus}`,
        date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
    })
  }

  let qnCounterA = 1
  let qnCounterB = 1
  let qnCounterC = 1
  let qnCounterD = 1

  return (
<div className={`max-w-6xl mx-auto mt-3 p-5 rounded-lg shadow-lg ${readOnly ? 'bg-gray-200' : 'bg-white '}`}>
  {loading ? <div className='h-screen w-screen'> Loading... </div> : (
  <div> 
    <div className='flex justify-between'>
      <h1 className='font-semibold underline text-2xl m-2 mb-4'>KYC Form - Risk Assessment (ACRA) </h1>
      <button onClick={handleRunAssessmentAgain} disabled={readOnly} className={`self-center w-2/12 py-1 px-2 bg-green-500 hover:bg-green-600 text-white font-semibold rounded-lg ${readOnly ? 'opacity-30' : 'opacity-100'}`} >
           Run Assessment again
      </button>
    </div>


    <>
      {/* Section A, Q1-11 */}
      <h2 className='font-semibold underline text-l m-2 mb-4'>
          Section A (Nature of Services Required by Customer/Client)
      </h2>
      <QuestionCard_ACRA disabled={readOnly} checkbox={true} inputName={"formingOfCorporations"} handleInputChange={handleInputChange} question={"Forming of corporations or other legal persons?"} 
        questionNumber={qnCounterA++} justification={false} value={formData?.formingOfCorporations}/>  

      <QuestionCard_ACRA disabled={readOnly} checkbox={true} inputName={"actingAsDirectorSecretaryPartner"} handleInputChange={handleInputChange} question={"Acting, or arranging for another person to act  (i) as a director or secretary of a corporation; (ii) partner of a partnership; or (iii) a position similar to the above in relation to other legal persons?"} 
        questionNumber={qnCounterA++} justification={false} value={formData?.actingAsDirectorSecretaryPartner}/>  

      <QuestionCard_ACRA disabled={readOnly} checkbox={true} inputName={"actingAsShareholder"} handleInputChange={handleInputChange} question={"Acting, or arranging for another person to act as a shareholder on behalf of any corporation other than one whose securities are listed on a securities exchange under section 2(1) or recognised securities exchange under section 283(1) of the Securities and Futures Act?"} 
        questionNumber={qnCounterA++} justification={false} value={formData?.actingAsShareholder}/>  

      <QuestionCard_ACRA disabled={readOnly} checkbox={true} inputName={"providingRegisteredOffice"} handleInputChange={handleInputChange} question={"Providing a registered office, business address or correspondence or administrative address or other related services?"} 
        questionNumber={qnCounterA++} justification={false} value={formData?.providingRegisteredOffice}/>  

      <QuestionCard_ACRA disabled={readOnly} checkbox={true} inputName={"buyingSellingRealEstate"} handleInputChange={handleInputChange} question={"Buying and selling of real estates?"} 
        questionNumber={qnCounterA++} justification={false} value={formData?.buyingSellingRealEstate}/>  

      <QuestionCard_ACRA disabled={readOnly} checkbox={true} inputName={"managingClientAssets"} handleInputChange={handleInputChange} question={"Managing of client money, securities or other assets?"} 
        questionNumber={qnCounterA++} justification={false} value={formData?.managingClientAssets}/>  

      <QuestionCard_ACRA disabled={readOnly} checkbox={true} inputName={"managingBankAccounts"} handleInputChange={handleInputChange} question={"Management of bank, savings or securities accounts?"} 
        questionNumber={qnCounterA++} justification={false} value={formData?.managingBankAccounts}/>  

      <QuestionCard_ACRA disabled={readOnly} checkbox={true} inputName={"organizingCompanyContributions"} handleInputChange={handleInputChange} question={"Organisation of contributions for the creation, operation or management of companies?"} 
        questionNumber={qnCounterA++} justification={false} value={formData?.organizingCompanyContributions}/>  

      <QuestionCard_ACRA disabled={readOnly} checkbox={true} inputName={"buyingSellingBusinessEntities"} handleInputChange={handleInputChange} question={"Buying and selling of business entities?"} 
        questionNumber={qnCounterA++} justification={false} value={formData?.buyingSellingBusinessEntities}/>  

      <QuestionCard_ACRA disabled={readOnly} checkbox={true} inputName={"statutoryAuditServices"} handleInputChange={handleInputChange} question={"Statutory audit services?"} 
        questionNumber={qnCounterA++} justification={false} value={formData?.statutoryAuditServices}/>  

      <QuestionCard_ACRA disabled={readOnly} checkbox={true} inputName={"providingOtherServices"} handleInputChange={handleInputChange} question={"Providing other services (e.g. Annual Return filing, etc.)?"} 
        questionNumber={qnCounterA++} justification={false} value={formData?.providingOtherServices}/>  
    </>


    <>
      {/* Section B, Q1-18 */}
      <h2 className='font-semibold underline text-l m-2 mb-4'>
          Section B - Risk Assessment of Customer/Client
      </h2>

      <QuestionCard_ACRA disabled={readOnly}  inputName={"newClient"} handleInputChange={handleInputChange} question={"Is this a new customer/client? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.newClient}/>  

      <QuestionCard_ACRA disabled={readOnly}  inputName={"cashIntensiveBusiness"} handleInputChange={handleInputChange} question={"Is the customer/client's business cash-intensive? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.cashIntensiveBusiness}/>  

      <QuestionCard_ACRA disabled={readOnly}  inputName={"frequentUnaccountedCashTransactions"} handleInputChange={handleInputChange} question={"Does the customer/client frequently make unaccounted cash transactions to similar recipient(s)? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.frequentUnaccountedCashTransactions}/>  

      <QuestionCard_ACRA disabled={readOnly}  inputName={"politicallyExposedPerson"} handleInputChange={handleInputChange} question={"Is any of the customer/client, beneficial owner or its agent a politically exposed person? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.politicallyExposedPerson}/>  

      <QuestionCard_ACRA disabled={readOnly}  inputName={"outdatedCompanyAccounts"} handleInputChange={handleInputChange} question={"Are the customer/client's company accounts outdated? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.outdatedCompanyAccounts}/>  


      <QuestionCard_ACRA disabled={readOnly}  inputName={"problemObtainingInfo"} handleInputChange={handleInputChange} question={"Is there any problem obtaining the required information in the relevant form? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.problemObtainingInfo}/>  

      <QuestionCard_ACRA disabled={readOnly}  inputName={"infoNotIndependentlyVerified"} handleInputChange={handleInputChange} question={"Is the information obtained not verified by independent and reliable sources? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.infoNotIndependentlyVerified}/>   

      <QuestionCard_ACRA disabled={readOnly}  inputName={"highRiskIndustry"} handleInputChange={handleInputChange} question={"Is the client in a high-risk industry? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.highRiskIndustry}/>  

      <QuestionCard_ACRA disabled={readOnly}  inputName={"isPublicCompanyNoDisclosure"} handleInputChange={handleInputChange} question={"Is the customer/client a public company listed on any stock exchange not subjected to disclosure requirements? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.isPublicCompanyNoDisclosure}/>  

      <QuestionCard_ACRA disabled={readOnly}  inputName={"isLegalPersonOrEntity"} handleInputChange={handleInputChange} question={"Is the customer/client a legal person or an entity that can hold assets in its own name? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.isLegalPersonOrEntity}/>  


      <QuestionCard_ACRA disabled={readOnly}  inputName={"usesNomineeDirectorsShareholders"} handleInputChange={handleInputChange} question={"Does the customer/client use nominee director(s) or shareholder(s)? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.usesNomineeDirectorsShareholders}/>  

      {/* This is extension part 4b */}
      <QuestionCard_ACRA disabled={readOnly}  inputName={"b. nomineeShareholdersMajorityOwnership"} handleInputChange={handleInputChange} question={"Where applicable, do the nominee shareholders represent majority ownership? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.nomineeShareholdersMajorityOwnership}/>  

      <QuestionCard_ACRA disabled={readOnly}  inputName={"unusualComplexOwnershipStructure"} handleInputChange={handleInputChange} question={"Does the ownership structure of the customer/client appear unusual or excessively complex given the nature of its business? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.unusualComplexOwnershipStructure}/>  

      <QuestionCard_ACRA disabled={readOnly}  inputName={"directorsCriminalConvictions"} handleInputChange={handleInputChange} question={"Do the proposed directors/partners/shareholders have prior criminal convictions involving fraud or dishonesty? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.directorsCriminalConvictions}/>   
       
      <QuestionCard_ACRA disabled={readOnly}  inputName={"frequentDirectorChanges"} handleInputChange={handleInputChange} question={"Do the customer/client's shareholders and/or directors frequently change, and the changes are within reason? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.frequentDirectorChanges}/>  


      <QuestionCard_ACRA disabled={readOnly}  inputName={"unregisteredNonProfitOrganisation"} handleInputChange={handleInputChange} question={"Is the customer/client a charitable or non-profit organisation that is not registered in Singapore? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.unregisteredNonProfitOrganisation}/>  

      <QuestionCard_ACRA disabled={readOnly}  inputName={"shellCompany"} handleInputChange={handleInputChange} question={"Does the client appear to be a shell company? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.shellCompany}/>  

      <QuestionCard_ACRA disabled={readOnly}  inputName={"hasAdverseScreeningResults"} handleInputChange={handleInputChange} question={"The professional firm has performed further screening of details of customer/client, beneficial owner of the customer/client, person acting on behalf of the customer/client, or connected party of the customer/client against other information sources, for example, Google, the sanctions lists published by the Office of Foreign Assets Control of the US Department of the Treasury, and/or other third party screening database.  Are there adverse news or information arising? "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.hasAdverseScreeningResults}/>  

      <QuestionCard_ACRA disabled={readOnly}  inputName={"hasUnsatisfactoryExceptions"} handleInputChange={handleInputChange} question={"Exceptions noted, from details of the customer/client, beneficial owner of the customer/client, person acting on behalf of the customer/client, or connected party of the customer/client, cannot be disposed of satisfactorily. "} 
        questionNumber={qnCounterB++} justification={false} value={formData?.hasUnsatisfactoryExceptions}/>  


      {/* Sanctions API Screening */}
      <SanctionsScreeningSection qnCounter={qnCounterB} readOnly={readOnly} formData={formData} kycData={kycData} 
        setFormData={setFormData} checkRiskStatus ={checkRiskStatus} decodedToken={decodedToken} clientFormId={clientFormId} />      

    </>

    <>
      {/* Section C, Q1-5 */}
      <h2 className='font-semibold underline text-l m-2 mb-4'>
          Section C 
      </h2>
      <QuestionCard_ACRA disabled={readOnly}  inputName={"connectedToHighRiskAmlCountry"} handleInputChange={handleInputChange} question={"Is the customer/client connected to or transacting with a country or a territory that is identified as not having adequate anti-money laundering or counter financing terrorism measures? "} 
        questionNumber={qnCounterC++} justification={false} value={formData?.connectedToHighRiskAmlCountry}/>   

      <QuestionCard_ACRA disabled={readOnly}  inputName={"connectedToHighRiskJurisdiction"} handleInputChange={handleInputChange} question={"Is the customer/client connected to or transacting with a country or a territory that have dealings with high risk jurisdiction? "} 
        questionNumber={qnCounterC++} justification={false} value={formData?.connectedToHighRiskJurisdiction}/>   

      <QuestionCard_ACRA disabled={readOnly}  inputName={"connectedToSanctionedCountry"} handleInputChange={handleInputChange} question={"Is the customer/client connected to or transacting with a country or a territory that is sanctioned by a regulatory body, such as the United Nations (UN)? "} 
        questionNumber={qnCounterC++} justification={false} value={formData?.connectedToSanctionedCountry}/>   

      <QuestionCard_ACRA disabled={readOnly}  inputName={"connectedToTerroristFundingCountry"} handleInputChange={handleInputChange} question={"Is the customer/client connected to or transacting with a country or a territory that is identified to be funding or supporting terrorist activities or that have designated terrorist organisations operating within their territories? "} 
        questionNumber={qnCounterC++} justification={false} value={formData?.connectedToTerroristFundingCountry}/>   

      <QuestionCard_ACRA disabled={readOnly}  inputName={"connectedToFATFCountry"} handleInputChange={handleInputChange} question={"Is the customer/client connected to or transacting with a country or a territory in relation to which the FATF has called for countermeasures? "} 
        questionNumber={qnCounterC++} justification={false} value={formData?.connectedToFATFCountry}/>   
    </>



    <>
      {/* Section D, Q1-9 */}
      <h2 className='font-semibold underline text-l m-2 mb-4'>
          Section D 
      </h2>
      <QuestionCard_ACRA disabled={readOnly}  inputName={"anonymousTransactionInstructions"} handleInputChange={handleInputChange} question={"Has the customer/client given any instruction to perform a transaction (which may include cash) anonymously? "} 
      questionNumber={qnCounterD++} justification={false} value={formData?.anonymousTransactionInstructions}/>   
      
      <QuestionCard_ACRA disabled={readOnly}  inputName={"fundsTransferWithoutUnderlyingService"} handleInputChange={handleInputChange} question={"Has the customer/client transferred any funds without the provision of underlying services or transactions? "} 
      questionNumber={qnCounterD++} justification={false} value={formData?.fundsTransferWithoutUnderlyingService}/>   
      
      <QuestionCard_ACRA disabled={readOnly}  inputName={"unusualTransactionPatterns"} handleInputChange={handleInputChange} question={"Are there unusual patterns of transactions that have no apparent economic purpose or cash payments that are large in amount, in which disbursement would have been normally made by other modes of payment (such as cheque, bank drafts etc.)? "} 
      questionNumber={qnCounterD++} justification={false} value={formData?.unusualTransactionPatterns}/>   
      
      <QuestionCard_ACRA disabled={readOnly}  inputName={"unaccountedPaymentsFromUnknownParties"} handleInputChange={handleInputChange} question={"Are there unaccounted payments received from unknown or un-associated third parties for services and/or transactions provided by the customer/client? "} 
      questionNumber={qnCounterD++} justification={false} value={formData?.unaccountedPaymentsFromUnknownParties}/>   
      
      <QuestionCard_ACRA disabled={readOnly}  inputName={"instructionForShellCompanyWithNominees"} handleInputChange={handleInputChange} question={"Is there instruction from the customer/client to incorporate shell companies with nominee shareholder(s) and/or director(s)? "} 
      questionNumber={qnCounterD++} justification={false} value={formData?.instructionForShellCompanyWithNominees}/>   
      
      <QuestionCard_ACRA disabled={readOnly}  inputName={"purchaseOfEntitiesWithoutCommercialPurpose"} handleInputChange={handleInputChange} question={"Does the customer/client purchase companies or business entities that have no obvious commercial purpose?  This would include:  ·       Multi-layer, multi-country and complex group structures.  ·       Setting up entities in Singapore where there is no obvious commercial purpose, or any other personal or economic connection to the client. "} 
      questionNumber={qnCounterD++} justification={false} value={formData?.purchaseOfEntitiesWithoutCommercialPurpose}/>   
      
      <QuestionCard_ACRA disabled={readOnly}  inputName={"relationshipWithoutPhysicalMeeting"} handleInputChange={handleInputChange} question={"Is this business relationship being established without any physical meeting? "} 
      questionNumber={qnCounterD++} justification={false} value={formData?.relationshipWithoutPhysicalMeeting}/>   
      
      <QuestionCard_ACRA disabled={readOnly}  inputName={"transactionsWithoutPhysicalMeeting"} handleInputChange={handleInputChange} question={"Are there any transactions being performed without any physical meeting? "} 
      questionNumber={qnCounterD++} justification={false} value={formData?.transactionsWithoutPhysicalMeeting}/>   
      
      <QuestionCard_ACRA disabled={readOnly}  inputName={"inconsistentWithClientRiskProfile"} handleInputChange={handleInputChange} question={"Are the transactions required by the customer/client inconsistent with the professional intermediaries’ knowledge on the customer/client’s risk profile and nature of business?"} 
      questionNumber={qnCounterD++} justification={false} value={formData?.inconsistentWithClientRiskProfile}/>    
    </>
 
    <Card>
      <p className='mb-2 font-semibold'> Outcome </p> <hr/>
      <p className='my-4'> The risk level is determined to be: {getRiskTag(formData?.riskLevel)} </p>
      
      <SubmissionCard  getRiskTag={getRiskTag} formData={formData} setFormData={setFormData} handleFileChange={handleFileChange} 
        getStatusTag={getStatusTag} handleStatusUpdate={handleStatusUpdate} handleSaveForm={handleSaveForm} 
        handleInputChange={handleInputChange} currentUrl={currentUrl} userRole={userRole}
        allreviewers={allreviewers} handleSubmitForReview={handleSubmitForReview} clientFormId={clientFormId}
        readOnly={readOnly}
        />
    </Card>

  </div>
  )}
</div>
  );
};


export default _RiskAssessment_ACRA


 