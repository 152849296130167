import React, { useContext, useEffect, useState } from 'react';
import _FormMonitoringTable from './form_user_table/_FormMonitoringTable'
import _ComplianceTopicMonitoringTable from './topics_monitoring_table/_ComplianceTopicMonitoringTable'
import { TokenContext } from '../../App';
import { jwtDecode } from 'jwt-decode';

const __ComplianceTable = () => {
      const [encodedAccessToken] = useContext(TokenContext) 
    const decodedToken = jwtDecode(encodedAccessToken)

  return (
    <div>
      <br/>  
       {/* The OR expression shortcircuits the condition checker to return True directly hence not rendering the Compliance Topic Table */}
      {['reviewer', 'preparer', 'viewer'].includes(decodedToken.role) || (<> <_ComplianceTopicMonitoringTable /> <br /><br />  </> )} 
      <_FormMonitoringTable /> 
    </div>
  );
};


export default __ComplianceTable