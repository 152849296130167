import React, { useState, useEffect, useContext } from 'react';
import { BsChevronDown, BsDownload, BsArchive, BsCopy } from 'react-icons/bs';
import { Row } from "../../shared/utils"
import { getkycAll, deletekyc, frontEndBaseURL, getkycPDF, generateFormToken  } from '../../../API/Api'
import { Link, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { jwtDecode } from 'jwt-decode';
import Tooltip from '@mui/material/Tooltip'; 
import 'react-toastify/dist/ReactToastify.css';
import { TokenContext } from '../../../App';


const _KycAmlArchivedCustomerTable = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortDirection, setSortDirection] = useState('desc');
  const [collapsed, setCollapsed] = useState(false); // State for collapsing the table
  const currentLocation = useLocation();
      const [encodedAccessToken] = useContext(TokenContext) 
    const decodedToken = jwtDecode(encodedAccessToken)
    const userRole = decodedToken.role 

useEffect(() => {
      const companyId = decodedToken.companyId
      getkycAll(companyId).then((response) => {
        const archivedKycData = []
        response.data.forEach(item => {
          if (item.archived) {
            archivedKycData.push({
              id: item._id,
              name: item.name,
              email: item.email,
              assignedDate: item.submitedDate,
              lastActivity: item.updatedDate,
              status: item.formStatus,
              maxPage: item.maxPage
            })
          }
        });
        setUsers(archivedKycData)
        setLoading(false);
      })
        .catch((error) => {
              console.error('Error fetching KYC data:', error);
          });
  }, [loading]);

  const copyFormLink = async (clientFormId, maxPage, companyId, user) => {
    if (userRole == 'viewer') { 
      toast.info("Viewer cannot perform that action")
      return 
    }

    const formTokenData = {
      name: user.name,
      userId : clientFormId,
      companyId : companyId,
    }

    const formToken = (await generateFormToken(formTokenData)).data.accessToken    
    const linkToCopy = frontEndBaseURL + '/form/kyc-aml?clientFormId=' + clientFormId + '&page=1&formToken=' + formToken;
    await navigator.clipboard.writeText(linkToCopy)

    toast.success("External Form Link Copied");
  }

  const handleKycPDF = async(userId, userName) => {
    const response = await getkycPDF(userId); // Update the URL with your backend API endpoint
    // const url = window.URL.createObjectURL(blob);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    const filename = `${userName}_Details_${DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm")}.pdf`;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortDirection('desc');
    }
  };

  const handleDelete = async (userId) => {
    if (userRole == 'viewer') { 
      toast.info("Viewer cannot perform that action")
      return 
    }
    await deletekyc(userId)
    setLoading(true) // have to trigger re-fetch from DB cuz need the added objectId
  }


  const sortedUsers = users.sort((a, b) => {
    if (sortBy === 'lastActivity') {
      if (sortDirection === 'asc') {
        return new Date(a.lastActivity) - new Date(b.lastActivity);
      } else {
        return new Date(b.lastActivity) - new Date(a.lastActivity);
      }
    }
    return 0;
  });

  const filteredUsers = sortedUsers.filter((user) =>
    user.name.toLowerCase().includes(searchTerm)
  );

  const handleCollapseToggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div >
      {/* <ToastContainer pauseOnHover={false} pauseOnFocusLoss={false} /> */}
      <Row>
        <div className="w-full flex justify-between items-center p-4 bg-white shadow">
          <strong>KYC Archive</strong>
          <input
            type="text"
            placeholder="Search Name"
            value={searchTerm}
            onChange={handleSearchChange}
            className="p-2 border border-gray-300 rounded-lg mr-2 w-1/3"
          />
          <Link to='/kyc-aml/customer-list' replace={currentLocation.pathname === '/'}>
            <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg">View Active</button>
          </Link>

        </div>
      </Row>
      {!collapsed && (
        <div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div>
            <table className="w-full border-collapse border border-gray-300 shadow table-auto">
              <thead>
                <tr className="bg-gray-300 text-left">
                  <th className="p-2">Name</th>
                  <th className="p-2">Email</th>
                  <th className="p-2">Assigned Date</th>
                  {/* <th className="p-2 cursor-pointer flex" onClick={() => handleSort('lastActivity')}> Last Activity  <span className='pl-2 pt-1'> <BsChevronDown  /> </span> <BsChevronDown className={`${Menu.isOpen && 'rotate-180'}`} /> </th> */}
                  <th className="p-2 cursor-pointer" onClick={() => handleSort('lastActivity')}> <span className='pl-2 pt-1 flex text-center'> Year of Declaration <BsChevronDown  className='ml-1 mt-1.5 flex' /> </span> {/* <BsChevronDown className={`${Menu.isOpen && 'rotate-180'}`} /> */} </th>
                  <th className="p-2 cursor-pointer" onClick={() => handleSort('lastActivity')}> <span className='pl-2 pt-1 flex'> Review Date <BsChevronDown  className='ml-1 mt-1.5 flex' /> </span> {/* <BsChevronDown className={`${Menu.isOpen && 'rotate-180'}`} /> */} </th>
                  <th className="p-2 cursor-pointer" onClick={() => handleSort('lastActivity')}> <span className='pl-2 pt-1 flex'> Approval Date <BsChevronDown  className='ml-1 mt-1.5 flex' /> </span> {/* <BsChevronDown className={`${Menu.isOpen && 'rotate-180'}`} /> */} </th>
                  <th className="p-2">Status</th>
                  <th className="p-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user, index) => (
                  <tr key={user.id} className={index % 2 === 0 ? 'bg-green-50 hover:bg-green-100' : 'bg-white hover:bg-green-100'}>
                  <Link to={`/kyc-aml/form?clientFormId=${user.id}&page=${user.maxPage}`} replace={currentLocation.pathname === `/kyc-aml/overview-assessment?clientFormId=${user.id}&page=${user.maxPage}`}>
                    <Tooltip title={<p style={{ fontSize: "14px" }}> Edit {user.type}, {user.name}, {user.formAuthority} </p>} className='border-stone-500' arrow>
                      <td className="p-2 w-3/12">{user.name}</td>
                    </Tooltip> 
                  </Link>
                  <td className="p-2 w-3/12"> {user.email} </td>
                  <td className="p-2 w-2/12 text-center">{user.nextRescreeningDate || '-'}</td>
                  <td className="p-2 w-1/12"> <label style={{ fontSize: 12 }} className="bg-purple-500 text-white rounded-full px-2 py-1 text-xs font-semibold"> Archived </label> </td>
                  <td className="p-2 w-1/12">
                    <div className="flex">
                      <Tooltip title={"Copy Form Link"} onClick={() => copyFormLink(user.id, user.maxPage, user.companyId, user)} className='p-2.5 mx-1 rounded-full border border-stone-500 cursor-pointer hover:bg-gray-400  transition duration-500' arrow>  <BsCopy/>  </Tooltip>
                      <Tooltip title={"Download"}  onClick={() => handleKycPDF(user.id, user.name)} className='p-2.5 mx-1 rounded-full cursor-pointer hover:bg-gray-400 border border-stone-500 transition duration-500' arrow> <BsDownload /> </Tooltip> 
                      <Tooltip title={"Delete"} onClick={() => handleDelete(user.id) } className='p-2.5 mx-1 rounded-full cursor-pointer hover:bg-gray-400 border border-stone-500 transition duration-500' arrow> <BsArchive /> </Tooltip>
                    </div>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
          )}
        </div>
      )}
    </div>
  );
};

 

export default _KycAmlArchivedCustomerTable;
