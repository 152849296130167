import React, {useContext} from 'react'
import { Row } from '../../shared/utils'
import { Tooltip } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import { TokenContext } from '../../../App'
const SubmissionCard = ({getRiskTag, setFormData, formData, handleFileChange, getStatusTag, handleStatusUpdate, handleSaveForm, currentUrl, userRole, handleInputChange, allreviewers, handleSubmitForReview, readOnly}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get('page'); 

  const [encodedAccessToken] = useContext(TokenContext) 
  const decodedToken = jwtDecode(encodedAccessToken)
  userRole = decodedToken.role

  const handleFileRemove = async (fileName) => {
    
    setFormData(prevState => ({
      ...prevState,
      [fileName] : ''
    }))
  }

  return (
    <div> 

      {/* <div className='my-3 border-2 p-1'>      
        Would you like to overide this suggested risk level?
        <label>
          <input
            className="p-1 mx-2"
            type="radio"
            name="overrideRiskSelected"
            value="true"
            checked={true}
            onChange={handleInputChange}
            disabled={readOnly}
          />
          Yes
        </label>
        <label>
          <input
            className="p-1 mx-2"
            type="radio"
            name="overrideRiskSelected"
            value="false"
            // checked={formData?.overrideRiskSelected === false}
            onChange={handleInputChange}
            disabled={readOnly}
          />
          No
        </label>

      {
        formData?.overrideRiskSelected === true && (
          <div>
            <span className='my-4'> The risk level has been manually set to: </span>
            <select disabled={readOnly} value={formData.overrideRiskLevel} name='overrideRiskLevel' onChange={handleInputChange} className='px-1.5 py-1 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500'>
              <option value="High">High</option>
              <option value="Standard">Standard</option>
              <option value="Low">Low</option>
            </select>
          </div>
        )
      }
      </div> */}
 


        { formData.riskLevel == 'High' && (
          <div className='bg-red-50 p-3 border-2 border-red-100'>
            <p className='mb-2 font-semibold'> Subsequent information request for High Risk</p> <hr/>

            <Row>
              <div className="mb-4">
                <p className='my-2 font-semibold'> Source of Wealth/Funds </p>
                {formData.sourceOfWealthDocumentURL && ( 
                  <> 
                    <a href={`${process.env.REACT_APP_BACKEND_URL}/get-upload?fileName=${formData.sourceOfWealthDocumentURL.split('/').slice(-1)[0]}`} target='_blank'  rel="noopener noreferrer"  className='text-blue-600'> 
                      {decodeURIComponent(formData.sourceOfWealthDocumentURL.split('/').slice(-1)[0])} 
                    </a>  
                    <span className='cursor-pointer' onClick={() => handleFileRemove('sourceOfWealthDocumentURL')}> ❌ </span> 
                  </>
                )}
                
                  <label className="cursor-pointer bg-gray-200 px-1 py-0.5 mt-1 rounded">
                    Browse
                    <input maxLength={800} type="file" name="sourceOfWealthDocumentURL" onChange={handleFileChange} className="pt-3 hidden"/>
                  </label>
              </div>

              <div className="mb-4">
                <p className='my-2 font-semibold'> Management Support </p> 
                { formData.managementSupportDocumentURL && ( 
                    <p> 
                      <a href={`${process.env.REACT_APP_BACKEND_URL}/get-upload?fileName=${formData.managementSupportDocumentURL.split('/').slice(-1)[0]}`} target='_blank'  rel="noopener noreferrer"  className='text-blue-600 my-2'> 
                        {decodeURIComponent(formData.managementSupportDocumentURL.split('/').slice(-1)[0])} 
                      </a> 
                      <span className='cursor-pointer' onClick={() => handleFileRemove('managementSupportDocumentURL')}> ❌ </span> 
                  </p>
                )}
                <label className="cursor-pointer bg-gray-200 px-1 py-0.5 mt-1 rounded">
                  Browse
                  <input maxLength={800} type="file" name="managementSupportDocumentURL" onChange={handleFileChange} className="pt-3 hidden"/>
                </label>
              </div>

              <div className="mb-4">
                <p className='my-2 font-semibold'> Other Documents </p> 
                { formData.otherDocumentsURL && ( 
                  <p>
                    <a href={`${process.env.REACT_APP_BACKEND_URL}/get-upload?fileName=${formData.otherDocumentsURL.split('/').slice(-1)[0]}`} target='_blank'  rel="noopener noreferrer"  className='text-blue-600 my-2'> 
                    {/* <a href={`${process.env.REACT_APP_BACKEND_URL}/get-upload?fileName=${formData.otherDocumentsURL.split('/').slice(-1)[0]}&accessToken=${token}`} target='_blank'  rel="noopener noreferrer"  className='text-blue-600 my-2'>  */}
                      {decodeURIComponent(formData.otherDocumentsURL.split('/').slice(-1)[0])}
                    </a>
                    <span className='cursor-pointer' onClick={() => handleFileRemove('otherDocumentsURL')}> ❌ </span> 
                  </p>
                )}
                <label className="cursor-pointer bg-gray-200 px-1 py-0.5 mt-1 rounded">
                  Browse
                  <input maxLength={800} type="file" name="otherDocumentsURL" onChange={handleFileChange} className="pt-3 hidden" />
                </label>
              </div>

            </Row>
          </div>
        )}

        {formData?.status == 'rejected' && ( 
          <p className='my-5 mt-7'> Rejected at: <span className='bg-gray-200 py-2 px-4 border border-b-gray-300 '> { formData.screenedDate } </span> </p> 
        )}

        {formData?.status == 'accepted' && ( 
          <div>
           <p className='my-5 mt-7'> Accepted at: <span className='bg-gray-200 py-2 px-4 border border-b-gray-300 '> { formData.screenedDate } </span> </p> 
           <p className='my-5 mt-7'> Next Screening at: 
              <span className='bg-gray-200 py-2 px-4 border border-b-gray-300 '> 
                {formData?.nextRescreeningDate.split('-')[0]}-
                {formData?.nextRescreeningDate.split('-')[1]}-
                <span className='text-purple-600'>{formData?.nextRescreeningDate.split('-')[2]}</span>
              </span> 

              { page != 3 && (
                <button  onClick={() => { window.location =  window.location.href.replace('page=2', 'page=3') }} className='ml-5 bg-green-500 hover:bg-green-600 text-white py-2 px-4 mr-5'> 
                  To Ongoing Monitoring
                </button>
              )}
           </p> 
         </div>
        )}
 
        
        <p className='my-5'> Acceptance Status: <span className='text-red-500'> {getStatusTag(formData.status)} </span> </p>
        { formData?.status && currentUrl !== '/form/kyc-aml'  &&  userRole !== 'preparer' &&  userRole !== 'viewer' && ( 
          <Row>
              { !readOnly && (
                <>
                  <div className='mb-6'>
                    <button className='bg-green-500 text-white py-2 px-4 mr-5 hover:bg-green-600' onClick={(e) => handleStatusUpdate(e, 'accepted')} >
                      Accept 
                    </button>
                    <button className={`bg-red-500 hover:bg-red-600 text-white py-2 px-4`} onClick={(e) => {handleStatusUpdate(e, "rejected")}} >
                      Reject 
                    </button> 
                  </div> 
                </>
              )}
          </Row>
        )}

        {formData?.status !== 'accepted' && currentUrl !== '/form/kyc-aml' &&  userRole !== 'viewer' && !readOnly && (  
          <>
            <Row>
              <div>
                <select value={formData?.reviewerReviewEmail} onChange={handleInputChange} name='reviewerReviewEmail'  className="p-1.5  mr-2 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"> 
                    <option> - Do not send Email - </option>   
                    { allreviewers.map(reviewer => ( 
                    <option value={reviewer.email}> {reviewer.name} - {reviewer.email} </option>        // when using .map, NEED ( after arrow => ( which is a 'return statement'
                  ))}    
                </select>
                <button className='bg-green-500 hover:bg-green-600 text-white py-2 px-4 mr-1' onClick={handleSubmitForReview}> Submit for Review </button>
                <Tooltip title={ <p> This sends an email with the current url to the  reviewer selected for them to review this risk assessment.</p>  }
                  arrow
                  componentsProps={{ 
                    tooltip: {
                      sx: { maxWidth: "445px", fontSize: "14px" },
                    },
                  }}
                > 
                  <span className='text-xl relative bottom-0.5' > ⓘ </span> 
                </Tooltip>
              </div>
              <button className='bg-green-500 hover:bg-green-600 text-white py-2 px-4 mr-5' onClick={handleSaveForm}> Save </button>
            </Row> 
          </>
        )} 

          <Row className='mb-4 justify-end'> 
              <div> Submitted by: {formData.submitPerson} {formData.submitPerson && "on"} {formData.submitDate} </div>
              <div> Reviewed by: {formData.approvalPerson} {formData.approvalPerson && 'on'} {formData.approvalDate} </div>
          </Row>


    </div>
  )
}

export default SubmissionCard