import React, { useContext } from 'react';
import { createUser,  updateUser } from '../../API/Api';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { TokenContext } from '../../App';
import { Tooltip } from '@mui/material';

const AddEditUserModal = ({user, handleCloseModal, allUsers, action, setLoading}) => {
      const [encodedAccessToken] = useContext(TokenContext) 
    const decodedToken = jwtDecode(encodedAccessToken)

  const userDetailsSchema = z.object({
    name: z.string().min(2, { message: "Name must be at least 2 characters long" }),
    email: z.string().min(1, { message: "Please enter email" }), // official email check removed to accomodate timothy.phua@caglobe.com/client_env types
    role: z.string(),
    subscriptions: z.object({ cmp: z.boolean(), fatca: z.boolean(), fit_proper: z.boolean(), kyc_aml: z.boolean() })
  })
  // .refine((data) => data.newPassword === data.confirmPassword, {
  //   message: "Please enter valid email format",
  //   path: ["email"],
  // })  

  const { register, handleSubmit, watch, reset , formState: {errors, isSubmitting } } = useForm({
    defaultValues: { name: user?.name, email: user?.email,  companyId:  decodedToken.companyId, companyName: decodedToken.companyName, isActive: user?.isActive || true, role: user?.role || 'admin',
      subscriptions: {
        cmp: user?.subscriptions?.cmp || false,
        fatca: user?.subscriptions?.fatca || false,
        fit_proper: user?.subscriptions?.fit_proper || false,
        kyc_aml: user?.subscriptions?.kyc_aml || true 
      }},
    resolver: zodResolver(userDetailsSchema)
  });
  
  const onSubmit = async (userData) => {
    // console.log(userData)
    userData = {
      ...userData,
      email: userData.email.trim(),
      inviter: decodedToken.name
    }

    try {
      if (action === 'Add') {
        const emailExist = allUsers.some(user => user.email === userData.email);
        if (emailExist) { 
          toast.error('User already exists with this email!!') 
          return
        }
        await createUser(userData)
        toast.success('Email invitation has been sent!');
      }
      
      if (action ==='Edit' ) { 
        const originalEmail = user?.email
        const isEmailTaken = allUsers
          .filter(user => user.email !== originalEmail)  // Exclude current email
          .some(user => user.email === userData.email);  // Check if new email exists
        
        if (isEmailTaken) { 
          toast.error('User already exists with this email') 
          return
        }
        await updateUser({
          ...userData,
          user_id : user._id
        } )
      }
 
      setLoading(true);
      handleCloseModal();


    } catch (error) { 
        toast.error('Something went wrong...');
      }
    }

    const x = watch()

  return (
 <div className="sm:w-[60vw] md:w-[45vw] lg:w-[40vw] xl:w-[30vw] p-5">  {/* //-> viewport for different breakpoints */}
      <form onSubmit={handleSubmit(onSubmit)}>
              <div className='mb-4'>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700"> Name: </label>
                  <input maxLength={800} {...register('name')} placeholder="Enter User Name" className="w-full p-1 font-normal border-b-2 border-b-gray-300 bg-gray-50 focus:outline-none focus:border-green-500" />
              </div> 
              {errors.name && <p className="text-red-500">{errors.name.message}</p>}

              <div className='mt-4'>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mt-3"> Email: </label>
                  <input maxLength={800} {...register('email')} placeholder="Enter User Email"  className="w-full p-1 font-normal border-b-2 border-b-gray-300 bg-gray-50 focus:outline-none focus:border-green-500"   />
              </div>
              {errors.email && <p className="text-red-500">{errors.email.message}</p>}

              <div className='mt-4 '>
                <p className="text-sm font-medium text-gray-700"> Role: </p>
                <select {...register('role')} className="w-full p-1 font-normal border-b-2 border-b-gray-300 bg-gray-50 focus:outline-none focus:border-green-500" >
                  <option value="admin">Admin</option>
                  <option value="reviewer">Reviewer</option>
                  <option value="preparer">Preparer</option>
                  <option value="viewer">View-Only</option>
                </select>
              </div>
              <div className='mt-2'>
                  <input maxLength={800} type="checkbox" {...register('subscriptions.kyc_aml')} className="w-4 h-4 mx-1"/> 
                  <Tooltip arrow title={ 
                    <div className='text-sm'>
                      <p> Reviewer and above can 'Approve' or 'Reject' Risk assessments </p>
                      <p> Preparers can only 'Submit for review' Risk assessments </p>
                    </div>
                    } >
                    <label className="text-sm font-medium text-gray-700 mt-3"> KYC/AML Portal </label> 
                    <span className='text-xl relative top-0.5' > ⓘ </span> <br/>
                  </Tooltip>

                  <input maxLength={800} type="checkbox" {...register('subscriptions.cmp')}  className="w-4 h-4 mx-1 inline"/> 
                  <Tooltip arrow title={ 
                    <div className='text-sm'>
                      <p> Admins can create Topics </p>
                      <p> Reviewers can 'Review' questions </p>
                      <p> Preparers can only 'Submit for review' questions </p>
                    </div>
                    } >
                    <label className="text-sm font-medium text-gray-700 mt-3"> Compliance Monitoring Program </label>
                    <span className='text-xl relative top-0.5' > ⓘ </span> <br/>
                  </Tooltip>

                  
                  {/* <input maxLength={800} type="checkbox" {...register('subscriptions.fatca')} className="w-4 h-4 mx-1"/>  
                  <label className="text-sm font-medium text-gray-700 mt-3"> Fit and Proper Declaration </label> <br/> */}
              </div>

              <div className='flex justify-end w-full mb-2'>
                <button type="submit" disabled={isSubmitting} className={`bg-green-500 mx-4 mt-2 text-white font-semibold px-3 rounded-sm ${isSubmitting ? 'opacity-50 cursor-not-allowed': 'hover:bg-green-600' }`}>
                  {isSubmitting ? 'Submitting...' : `${action} User`}
                </button>

                <p onClick={handleCloseModal} className='cursor-pointer bg-gray-400 mt-3 text-white font-semibold py-2 px-4 rounded-sm hover:bg-gray-500'>
                  Cancel 
                </p>
              </div>
      </form>
    </div>
  );
};

export default AddEditUserModal;


   