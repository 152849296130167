import React, {useEffect, useState, useContext} from 'react';
import { useLocation } from 'react-router-dom';
import { getkyc  } from "../../../API/Api"
import _IndividualDetails from './_IndividualDetails'
import _EntityDetails from './_EntityDetails'
import _RiskAssessment_MAS from './_RiskAssessment_MAS' 
import _RiskAssessment_ACRA from './_RiskAssessment_ACRA' 
import PageNotFound from '../../../PageNotFound'
import OnGoingMonitoring from './OnGoingMonitoring';
import FormSteps from './FormSteps'
import { ToastContainer } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { TokenContext } from '../../../App';

const __KycAmlForm = () => {
  const [encodedAccessToken] = useContext(TokenContext) 
  const location = useLocation();

  const currentUrl = location.pathname
  const queryParams = new URLSearchParams(location.search);
  const token = encodedAccessToken || queryParams.get('formToken') 
  const clientFormId = queryParams.get('clientFormId') 
  const currentPage = queryParams.get('page'); 
  const decodedToken = jwtDecode(token)
 
  const companyId = decodedToken?.companyId; 

  const [clientData, setclientData] = useState({name: '', type: '', formStatus: '', maxPage:'3'}) // maxPage 3 initial value to not show 'please complete previous steps' duirng inital load
  const [formCurrentPage, setformCurrentPage] = useState(currentPage) // not in use?

  useEffect( () => {
    const fetchData = async () => { 
      const kycData = await getkyc(clientFormId, token) 
      setclientData(kycData.data)
    }
    fetchData()
      }, 
  [formCurrentPage]);

  let showSubmitButton = true;
  let readOnlyOne = false;
  let readOnlyTwo = false; 
  if (clientData.maxPage > 1 || decodedToken.role == 'viewer') { 
    // readOnlyOne=true 
    showSubmitButton = false;
  }
  if (clientData.maxPage > 2 || decodedToken.role == 'viewer' || clientData.archived) { 
    readOnlyOne=true
    readOnlyTwo=true
    }


  if (!clientData) {  return <PageNotFound /> } 
     
  // This is External link for Page 1
  if (currentUrl === '/form/kyc-aml' ) { // check (!decodedToken?.email), if want to know its formToken or userToken  
      return (
      <div className='flex flex-col items-center justify-center min-h-screen w-full bg-slate-300 absolute top-0 left-0 right-0 z-50'>
        <ToastContainer pauseOnHover={false} pauseOnFocusLoss={false} limit={3} autoClose={2750}/>
        {clientData.type === 'individual'  && <_IndividualDetails token={token} showSubmitButton={showSubmitButton} currentUrl={currentUrl}  individualName={clientData.name} clientFormId={clientFormId} companyId={companyId} readOnly={readOnlyOne} maxPage={clientData.maxPage}/>}
        {clientData.type === 'entity' && <_EntityDetails token={token} showSubmitButton={showSubmitButton}  currentUrl={currentUrl}  companyName={clientData.name} clientFormId={clientFormId} companyId={companyId} readOnly={readOnlyOne} maxPage={clientData.maxPage}/>} 
      </div>
  )}
  

  // This is Internal link for Page 1
  if (currentPage == 1 && clientData.maxPage >= 1) {
    return <>
      <FormSteps/>
      {clientData.type === 'individual'  && <_IndividualDetails token={token} showSubmitButton={showSubmitButton} currentUrl={currentUrl} individualName={clientData.name} clientFormId={clientFormId} readOnly={readOnlyOne} formAuthority={clientData.formAuthority} />}
      {clientData.type === 'entity' && <_EntityDetails token={token} showSubmitButton={showSubmitButton} currentUrl={currentUrl} companyName={clientData.name} clientFormId={clientFormId} readOnly={readOnlyOne} formAuthority={clientData.formAuthority}  />} 
    </>


  // Only Page 2 has ACRA vs MAS form difference 
  } else if (currentPage == 2 && clientData.maxPage >= 2 && clientData.formAuthority === 'MAS') {
    return <>
      <FormSteps/>
      <_RiskAssessment_MAS currentUrl={currentUrl} clientFormId={clientFormId} userType={clientData.type} readOnly={readOnlyTwo} formAuthority={clientData.formAuthority} /> 
    </>
  } else if (currentPage == 2 && clientData.maxPage >= 2 && clientData.formAuthority === 'ACRA') {
    return <>
      <FormSteps/>
      <_RiskAssessment_ACRA currentUrl={currentUrl} clientFormId={clientFormId} userType={clientData.type} readOnly={readOnlyTwo} formAuthority={clientData.formAuthority} /> 
    </>



  // Page 3 is same for all
  } else if (currentPage == 3 && clientData.maxPage >= 3) {
    return <>
      <FormSteps/>
      <OnGoingMonitoring  currentUrl={currentUrl} clientFormId={clientFormId} userType={clientData.type} />  
    </>

  } else {
    return <>
      <FormSteps />
      <div> Please complete previous form steps! </div>
    </>
  }
};

export default __KycAmlForm;
