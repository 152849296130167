import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { getkycAll } from '../../../API/Api'
import { jwtDecode } from "jwt-decode";
          
import {
  Chart as ChartJS, LineElement, PointElement, 
  LinearScale, CategoryScale, Tooltip, Legend } from 'chart.js';

// Register the elements
ChartJS.register( 
  LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend );


const FormStatusChart = () => {  
 

  const data = {
    labels: ['02/08/2024', '30/07/2024', '29/07/2024', '24/01/2024'], // X-axis labels
    datasets: [
      {
        label: 'reviewed',
        data: [2, 1, 3, 2],
        borderColor: '#0D1F47', // Navy color
        backgroundColor: 'rgba(13, 31, 71, 0.1)',
        fill: true,
        pointBackgroundColor: '#0D1F47',
        pointBorderColor: '#0D1F47',
        tension: 0.4,
      },
      {
        label: 'pendingAction',
        data: [0, 10, 3, 2],
        borderColor: '#FFAA00', // Orange color
        backgroundColor: 'rgba(255, 170, 0, 0.1)',
        fill: true,
        pointBackgroundColor: '#FFAA00',
        pointBorderColor: '#FFAA00',
        tension: 0.4,
      },
      {
        label: 'pendingReview',
        data: [1, 3, 2, 1],
        borderColor: '#8997A5', // Gray color
        backgroundColor: 'rgba(137, 151, 165, 0.1)',
        fill: true,
        pointBackgroundColor: '#8997A5',
        pointBorderColor: '#8997A5',
        tension: 0.4,
      },
    ],
  };
  
  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        stepSize: 1,
        ticks: {
          precision: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 20,
          padding: 20,
        },
      },
    },
    maintainAspectRatio: true,
  };
  

    return (
      <Line 
        data={data} 
        options={options}
        height={400}
        width={800}
      />
    );
  }


export default FormStatusChart;
