import React, { useState, useEffect } from 'react';
import SanctionsioScreeningCard from './SanctionsioScreeningCard';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { createKycNestedSanctionComment, worldCheckHits, sanctionIoHits } from "../../../API/Api" 
import { toast } from 'react-toastify';

const SanctionsScreeningSection = ({userId, decodedToken, readOnly, setFormData, qnCounter, formData, kycData, checkRiskStatus}) => {
  const [assessmentRunning, setAssessmentRunning] = useState(false);
  const [screeningSource, setScreeningSource] = useState('sanctions.io')  
  const [apiKey, setApiKey] = useState('') // for worldCheck
  const [apiSecret, setApiSecret] = useState('') // for worldCheck

    const handleSubmitNestedArrayComment = async (e, formName, nestedName, index) => {
        if (formData[formName][index][nestedName].trim() == '') { return }
     
        const newCommentEntry = {
          screenedDate: formData.screenedDate,
          kycaml_client_id: userId,
          question: formName + ' ' + index.toString(),
          content : formData[formName][index][nestedName],
          name : decodedToken.name,
          commented_at : DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
          comment_id : uuidv4()  
        }
        await createKycNestedSanctionComment(newCommentEntry)   
        formData[formName][index].comments.push(newCommentEntry);
        const newCommentArray = formData[formName][index].comments
    
        const newPepArray = [...formData[formName]]
        newPepArray[index].comments = newCommentArray
        newPepArray[index].newComment = ''
    
        setFormData((prevState) => ({
          ...prevState,
          [formName]: newPepArray
      }));
      
      }
  
    const handleNestedArrayInputChange = (e, nestedName, index) => {
        let { name, value } = e.target; 
        name = name.split(' ')[0]
        const newArrayResult = [...formData[name]]
        newArrayResult[index][nestedName] = value
    
        const newFormData = {  
          ...formData,          
          [name]: newArrayResult
        }
        setFormData(newFormData) 
        checkRiskStatus(newFormData)
      }

    const handleRunAssessment = async (screeningSource) => {
      setAssessmentRunning(true)
      toast.info(`Assessment for ${screeningSource} running... `)

      try {
        const screenedName = kycData.name
        const entityType = kycData.type.toUpperCase()

        if ( ['500_Global'].includes(decodedToken.companyId) ) { // for now hardcore 500 to only use worldCheck
        // if (screeningSource == 'worldCheck') { 
          const payload = { 
            apiKey, apiSecret, 
            screenedName, 
            connectedParty : kycData.details?.connectedParty, 
            entityType : entityType, 
            kycaml_client_id : kycData['_id'],
            screenedDate : formData['screenedDate'], 
            screeningSource: 'worldCheck'
           }
          
          const worldCheckResponse = await worldCheckHits(payload)

          if (worldCheckResponse.data.message == 'ERR_BAD_REQUEST') { 
            toast.error('Invalid worldCheck API key/secret!')
          } else {
            const {pepHits, crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits} = worldCheckResponse.data
            setFormData({...formData, pepHits,crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits, screeningSource})
            toast.success(`Assessment complete!`)
          }

        } 
        else {
        // else if (screeningSource == 'sanctions.io') {
          const payload = { 
            screenedName:kycData['name'],
            connectedParty:kycData?.details?.connectedParty,
            entityType:kycData['type'],
            kycaml_client_id : kycData['_id'],
            screenedDate : formData['screenedDate'],
            screeningSource: 'sanctions.io' 
          }

          const {pepHits, crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits} = (await sanctionIoHits(payload)).data
          setFormData({...formData, pepHits,crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits, screeningSource})
          toast.success(`Assessment complete!`)
        }

      } catch(e) {
        console.log(e)
        toast.error('Something went wrong...')
      }

      setAssessmentRunning(false)
      return
    }

      
  return (
    <div>

      { (decodedToken.companyId == 'Test_Company' ||  decodedToken.companyId == 'ambridge_Advisers' ||  decodedToken.companyId == '00_Global')  && ( 
      <>
        <div className="flex space-x-4">
            <select value={screeningSource} onChange={(e) => setScreeningSource(e.target.value)} className="w-2/12 py-1 px-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500">
              <option value="" disabled> - Select Screening Source- </option>
              <option value="sanctions.io">Sanctions.io</option>
              <option value="worldCheck">World Check</option>
            </select>
            
            {screeningSource === 'worldCheck' && (
              <>
                <input maxLength={800} placeholder="Enter WorldCheck API Key" value={apiKey} onChange={(e) => setApiKey(e.target.value)} className="w-3/12 py-1 px-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
                <input maxLength={800} type='password' placeholder="Enter WorldCheck API Secret" value={apiSecret} onChange={(e) => setApiSecret(e.target.value)} className="w-5/12 py-1 px-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
              </>
            )}
      
          <button onClick={() => handleRunAssessment(screeningSource)} disabled={assessmentRunning || readOnly} className={`w-2/12 py-1 px-2 bg-green-500 hover:bg-green-600 text-white font-semibold rounded-lg ${assessmentRunning || readOnly ? 'opacity-30' : 'opacity-100'}`} >
            Run Screening
          </button>
        </div>
        <u> Showing results for: {formData.screeningSource} </u>
      </>
      )}


      <SanctionsioScreeningCard disabled={readOnly} questionNumber={qnCounter++} handleSubmitComment={handleSubmitNestedArrayComment} result={formData} 
        inputName={"pepHits"} pepExtra={kycData} handleInputChange={handleNestedArrayInputChange} question={"Were there any hits for PEP screening?"} 
        tableHeaders={[
          {displayTitle: 'Name/title', width : 'w-3/12', dataField: (result) => `${result?.name} - \n ${result?.title || ''}` },
          {displayTitle: 'Position start', width : 'w-1.5/12', dataField: (result) => result?.startDate },
          {displayTitle: 'Position end', width : 'w-1.5/12', dataField: (result) => result?.endDate },
          {displayTitle: 'True Hit?', width : 'w-1/12', dataField: null },
          {displayTitle: 'Justification', width : 'w-5/12', dataField: null },
        ]}
      />
      <SanctionsioScreeningCard disabled={readOnly} questionNumber={qnCounter++} handleSubmitComment={handleSubmitNestedArrayComment} result={formData} 
        inputName={"crimesHits"} handleInputChange={handleNestedArrayInputChange} question={"Were there any hits for crime?"} 
        tableHeaders={[
          {displayTitle: 'Name / DataSource', width : 'w-2/12', dataField: (result) => `${result.name} \n ${result.dataSource}` },
          {displayTitle: 'Remarks', width : 'w-4/12', 
            dataField: (result) => {
              return ( // you can either return a plain string like above, or return <> JSX </> with 'return'
                <>
                  {result?.federal_register_notice?.substring(0, 280) + (result?.federal_register_notice?.length > 280 ? '...' : '')} {/* 280 characters approx 70 words */}
                  <a className='text-blue-500' href={result?.source_list_url} target="_blank" rel="noopener noreferrer"> Link </a> {/* rel="noopener noreferrer", byppasses the API block somehow */} 
                </> );
            }}, 
          {displayTitle: 'True Hit?', width : 'w-1/12', dataField: null },
          {displayTitle: 'Justification', width : 'w-5/12', dataField: null },
        ]}
      />
      <SanctionsioScreeningCard disabled={readOnly} questionNumber={qnCounter++} handleSubmitComment={handleSubmitNestedArrayComment} result={formData} 
        inputName={"sanctionsHits"} handleInputChange={handleNestedArrayInputChange} question={"Were there any hits for sanctions?"} 
        tableHeaders={[
          {displayTitle: 'Name', width : 'w-3/12', dataField: (result) => result.name },
          {displayTitle: 'DataSource', width : 'w-3/12',
              dataField: (result) => {
                return ( 
                  <>
                    {result.dataSource} 
                    <a className='text-blue-500' href={result?.source_list_url} target="_blank" rel="noopener noreferrer"> Link </a>
                  </> );
                }},
          {displayTitle: 'True Hit?', width : 'w-1/12', dataField: null },
          {displayTitle: 'Justification', width : 'w-5/12', dataField: null },
        ]}
      />
      <SanctionsioScreeningCard disabled={readOnly} questionNumber={qnCounter++} handleSubmitComment={handleSubmitNestedArrayComment} result={formData} 
        inputName={"highRiskJurisdictionHits"} handleInputChange={handleNestedArrayInputChange} question={"Were there any hits for High Risk Jurisdiction?"} 
        tableHeaders={[
          {displayTitle: 'Name', width : 'w-3/12', dataField: (result) => result.name },
          {displayTitle: 'Data Source', width : 'w-3/12', 
            dataField: (result) => {
              return ( 
                <>
                  {result.dataSource} 
                  <a className='text-blue-500' href={result?.source_list_url} target="_blank" rel="noopener noreferrer"> {result.source_list_url} </a>
                </> );
              }},
          {displayTitle: 'True Hit?', width : 'w-1/12', dataField: null },
          {displayTitle: 'Justification', width : 'w-5/12', dataField: null },
        ]}
      />
      <SanctionsioScreeningCard disabled={readOnly} questionNumber={qnCounter++} handleSubmitComment={handleSubmitNestedArrayComment} result={formData} 
        inputName={"adverseNewsHits"} handleInputChange={handleNestedArrayInputChange} question={"Are there any adverse news?"} 
        tableHeaders={[
          {displayTitle: 'Title', width : 'w-2.5/12', dataField: (result) => result.title },
          {displayTitle: 'Description', width : 'w-3.5/12', 
            dataField: (result) => {
              return ( 
                <>
                  {result.description} 
                  <a className='text-blue-500' href={result?.url} target="_blank" rel="noopener noreferrer"> Link </a>
                </> );
              }},
          {displayTitle: 'True Hit?', width : 'w-1/12', dataField: null },
          {displayTitle: 'Justification', width : 'w-5/12', dataField: null },
        ]}
      /> 
    </div>
  )
}

export default SanctionsScreeningSection