import React from 'react'
import { Card } from '../utils'

const ChangeLog = () => {

    const moduleTag = (module) => {
        if (module === 'cmp') {
            return <label className="bg-yellow-200 text-black rounded-full px-1.5 py-0.5 text-xs font-semibold text-center"> CMP</label> 
        }
        else if (module == 'kyc') {
            return <label className="bg-blue-300 text-black rounded-full px-1.5 py-0.5 text-xs font-semibold text-center"> KYC</label>
        } 
      }


      const kyc = 'kyc'
      const cmp = 'cmp'

  return (
    <>
        <Card>
            <strong className='text-xl mb-2'> Change Log </strong>
        </Card>
    
        <Card> 
            <ul>
                <strong className='text-lg mb-2'> Version 1.0.05 </strong>
                <li> {moduleTag(kyc)} Update  preperer role needs OTP to log in </li>
                <li> {moduleTag(kyc)} Reviewer and Admin can now 'submit for review' on page2 as well </li>
                <li> Reactivated SuperAdmin page </li>
                {/* <li> {moduleTag(kyc)} Add ACRA form option </li> */}
                {/* <li> {moduleTag(kyc)} Custom interval screening added </li> */}
                {/* <li> {moduleTag(kyc)} Add Entity as relevant party option </li> */}
            </ul> 
            <ul>
                <strong className='text-lg mb-2'> Version 1.0.04 </strong>
                <li> Renamed Roles Executive to 'preparer' and Manager to 'reviewer' </li>
                <li> {moduleTag(cmp)} Add Tags to CMP forms </li>
                <li> {moduleTag(cmp)} Add Download Logs to CMP forms </li>
                <li> {moduleTag(cmp)} Add option to add emails reminders for members outside of organisation </li> 
                <li> {moduleTag(kyc)} Add tool tips explaining "Send notfication email" and "Submit for review" buttons </li>
                <li> {moduleTag(kyc)} Simplified options for Entity Nature of Business </li>
            </ul> 
            <br/>
            <ul>
                <strong className='text-lg mb-2'> Version 1.0.03 </strong>
                <li> {moduleTag(kyc)} Add mandatory fields</li>
                <li> {moduleTag(kyc)} Add 'Account manager' field</li>
                <li> {moduleTag(kyc)} Add source of wealth/fund definitions</li>
                <li> {moduleTag(kyc)} Add summary report & individual report download</li>
            </ul> 
            <br/>
            <ul>
                <strong className='text-lg mb-2'> Version 1.0.02 </strong>
                <li> Password need 8 characters long with special characters  </li>
                <li> Auto log out if inactive for 2 hours</li>
                <li> {moduleTag(cmp)} Add multi-select emails to notify reviewer</li>
                <li> {moduleTag(cmp)} Add creator email for Topic </li>
                <li> {moduleTag(cmp)} Remove columns for Pending Action/review/reviewed in forms </li>
            </ul> 
            <br/>
            <ul>
                <strong className='text-lg mb-2'> Version 1.0.01 </strong>
                <li> {moduleTag(kyc)} Add “Singapore” as default selected nationality option </li>
                <li> {moduleTag(kyc)} Add remove uploaded files feature  </li>
                <li> {moduleTag(kyc)} Add sidebar feedback link </li>
                <li> {moduleTag(kyc)} Add "Viewer" role </li>
                <li> {moduleTag(kyc)} Add notification in top menu  </li>
                <li> {moduleTag(kyc)} Fix email not sent </li>
                </ul> 
            <br/>
        </Card>
    </>
  )
}

export default ChangeLog


 
