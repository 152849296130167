import React, {useContext} from 'react'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { TokenContext } from '../../../App';

const FormSteps = ({ currentStep, setCurrentStep }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get('page'); 
  const [encodedAccessToken] = useContext(TokenContext) 
  const decodedToken = jwtDecode(encodedAccessToken)

  //  add onClick to those <Link> to trigger setformCurrentPage, which allow it to refetch latest kyc results

  return (
    <div className="flex justify-between items-start w-full max-w-3xl mx-auto mt-4">
      <div className="flex flex-col items-center">
      <Link to={window.location.href.replace(/page=\d+/, 'page=1')} replace={window.location.href.replace(/page=\d+/, 'page=1')} onClick={() => window.scrollTo(0, 0)} className={`${currentPage == 1 ? 'bg-gray-400' : 'bg-gray-300' } w-40 h-10 p-2.5 rounded-full flex justify-center items-center font-bold text-lg transition-all duration-300 ease-in-out mb-0.5 cursor-pointer text-gray-600 hover:bg-gray-400`}>
          1
          <span className="p-1 text-xs text-center font-medium text-gray-600 mt-0.5">
            Client Details
          </span>
      </Link> 
      
      </div>

      <div className="flex-grow h-0.5 mt-6 mx-2 bg-gray-300 transition-all duration-300 ease-in-out"></div>

      <div className="flex flex-col items-center">
      <Link to={window.location.href.replace(/page=\d+/, 'page=2')} replace={window.location.href.replace(/page=\d+/, 'page=2')} onClick={() => window.scrollTo(0, 0)} className={`${currentPage == 2 ? 'bg-gray-400' : 'bg-gray-300' } w-40 h-10 p-2.5 rounded-full flex justify-center items-center font-bold text-lg transition-all duration-300 ease-in-out mb-0.5 cursor-pointer text-gray-600 hover:bg-gray-400`}>
          2
          <span className="p-1 text-xs text-center font-medium text-gray-600 mt-0.5">
            { decodedToken?.kycSettings?.nameScreenOnly ? 'Screening' : 'Risk Assessment'}
          </span>
      </Link> 
      </div>

      <div className="flex-grow h-0.5 mt-6 mx-2 bg-gray-300 transition-all duration-300 ease-in-out"></div>

      <div className="flex flex-col items-center">
      <Link to={window.location.href.replace(/page=\d+/, 'page=3')} replace={window.location.href.replace(/page=\d+/, 'page=3')} onClick={() => window.scrollTo(0, 0)} className={`${currentPage == 3 ? 'bg-gray-400' : 'bg-gray-300' } w-40 h-10 p-2.5 rounded-full flex justify-center items-center font-bold text-lg transition-all duration-300 ease-in-out mb-0.5 cursor-pointer text-gray-600 hover:bg-gray-400`}>
          3
          <span className="p-1 text-xs text-center font-medium text-gray-600 text-line mt-0.5">
            Ongoing Monitoring
          </span>
      </Link> 
      </div>
    </div>
  );
};

export default FormSteps