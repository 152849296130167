import React, { useContext, useEffect, useState } from 'react';
import { BsBell, BsPerson, BsBoxArrowRight  } from "react-icons/bs";
import { jwtDecode } from 'jwt-decode';
import { getCompanyDetails, getAllNotifications, getUserById, logoutUser, updateUser } from '../../API/Api';
import { DateTime, Duration } from 'luxon';
import { LuBellDot } from "react-icons/lu";
import BasicMenu from './BasicMenu'
import { Link } from 'react-router-dom';
import { TokenContext } from '../../App';

const TopBar = ({setIsLoggedIn}) => {
  const [allNotifications, setAllNotifications] = useState([]);
  const [image, setImage] = useState(); 

  let [encodedAccessToken] = useContext(TokenContext) 
  const decodedToken = jwtDecode(encodedAccessToken)
  const [companyName, setCompanyName] = useState(); 

  useEffect(() => {
    const fetchCompanyLogo = async () => {
      const companyData = await getCompanyDetails('company')
      setCompanyName(companyData?.data?.companyName )
 
      if (companyData?.data?.logoUrl) {
        setImage(companyData?.data?.logoUrl);
      }
      await new Promise(r => setTimeout(r, 500))
      
      const notifications = await getAllNotifications('')  
      setAllNotifications(notifications?.data)

    }

    if (decodedToken) {
      fetchCompanyLogo()
    }

  }, []);
 

  function getRelativeTimeString(timestamp) {
    const pastDateTime = DateTime.fromFormat(timestamp, 'dd-MM-yyyy HH:mm:ss'); // Parse the given timestamp
    const now = DateTime.now();
    const diff = now.diff(pastDateTime); // Calculate the difference
    
    const days = diff.as('days'); // Convert to units
    const hours = diff.as('hours');
    const minutes = diff.as('minutes');
    
    if (days >= 1) {
      return `${Math.floor(days)} day${days >= 2 ? 's' : ''} ago`;
    } else if (hours >= 1) {
      return `${Math.floor(hours)} hour${hours >= 2 ? 's' : ''} ago`;
    } else if (minutes >= 1) {
      return `${Math.floor(minutes)} minute${minutes >= 2 ? 's' : ''} ago`;
    } else {
      return 'Just now';
    }
  }

  async function handleLogOut() {
    await logoutUser()
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('stringToken')
    window.location = '/login';
  }


  return (
    <nav className="bg-green-500 z-10 p-1.5 sticky top-0 ">
      <div className="flex justify-end items-center">
        <div>
          {image ? (
              <img src={image} alt="Profile" style={{ width: '8%', height: '8%', objectFit: 'cover' }} className='bg-white float-right'/>
            ) : (
              <span className='font-serif border-y border-green-300 px-2 '> {companyName} </span>
            )}
        </div>

        <BasicMenu icon={ true ? <BsBell className='text-white scale-150 mx-3'/> : <LuBellDot className='scale-150 text-red-500 mx-3'/>} >
        <div className='bottom-2 relative'>
              <p className="px-4 py-2 bg-cyan-900 sticky top-0 border-2 border-cyan-900 text-white rounded-t">Notifications</p>
              <ul> 
                {allNotifications.map((notification, index) => (
                  <li key={index} className="px-4 py-2 hover:bg-green-100 hover:rounded-lg">
                    <div className='flex justify-between'>
                      <h4 className="font-bold mb-2"> {notification.type} </h4>
                      <p className='text-sm text-gray-800'> {getRelativeTimeString(notification.date)} </p>
                    </div>
                    <p> {notification.text} </p>  
                  </li>
                ))}
              </ul>
            </div>
        </BasicMenu>

        <BasicMenu icon={<BsPerson className='scale-150 text-white mr-5'/> } >
          <Link to='/profile' className="text-black px-4 py-2 hover:bg-gray-200 hover:rounded-lg flex justify-between"> User Profile <BsPerson className='scale-125 inline ml-3'/>  </Link>
            <span href="/login" onClick={handleLogOut} className="text-black px-4 py-2 hover:bg-gray-200 hover:rounded-lg flex justify-between cursor-pointer"> Logout <BsBoxArrowRight className='scale-125 inline'/> </span>
        </BasicMenu>
      </div>
    </nav>
  );
};

export default TopBar;


