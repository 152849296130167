import React, { useState, useRef, useContext } from 'react';
import { BsChevronDown, BsListCheck , BsBriefcaseFill, BsClockHistory , BsFillPatchQuestionFill, BsFiles, BsServer, BsFillHouseFill, BsPersonFillGear, BsWallet   } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import iCompass_icon from '../../images/iCompass_icon.png'
import iCompass_icon_crop from '../../images/iCompass_icon_crop.png'
import { jwtDecode } from 'jwt-decode';
import { TokenContext } from '../../App';


const SideBar = ( {setOpen, open} ) => {
    const [encodedAccessToken] = useContext(TokenContext) 
    const decodedToken = jwtDecode(encodedAccessToken) 
  const Menus = [
    // { title: 'Home', src: '/', icon: <BsFillHouseFill /> },
    // { title: 'Admins', src: '/admin', icon: <BsPersonFillGear  /> },

    { title: 'Compliance Monitoring Program', src: '/compliance-monitoring', icon: <BsListCheck />, code: 'cmp',
      subMenus: [
        // {
        //   title: 'Dashboard',
        //   src: '/compliance-monitoring',
        //   cName: 'sub-nav',
        // },
        {
          title: 'Compliance Monitoring',
          src: '/compliance-monitoring/monitoring',
          cName: 'sub-nav',
        },
      ], isOpen: false
    },

    { title: 'Fit and Proper Declaration', src: '/fit-and-proper', icon: <BsServer />,
    subMenus: [
      {
        title: 'Dashboard',
        src: '/fit-and-proper',
        cName: 'sub-nav',
      },
      {
        title: 'Reporting Status',
        src: '/fit-and-proper/reporting-status',
        cName: 'sub-nav',
      },
    ], isOpen: false
    },

    { title: 'KYC/AML Portal', src: '/kyc-aml', icon: <BsBriefcaseFill  />, code: 'kyc_aml',
      subMenus: [
        // {
        //   title: 'Dashboard',
        //   src: '/kyc-aml',
        //   cName: 'sub-nav',
        // },
        {
          title: 'Customer List',
          src: '/kyc-aml/customer-list',
          cName: 'sub-nav',
        }
        
        // {
        //   title: 'Transaction History',
        //   src: '/kyc-aml/transaction-history',
        //   cName: 'sub-nav',
        // },
      ], isOpen: false
    }
  ];

  
  const [Menu, SetMenu] = useState(Menus)
  const currentLocation = useLocation();

  const setSubMenuOpen = (index) => {
    SetMenu((prevMenus) =>
      prevMenus.map((menu, i) => {
        if (i === index) {
          return { ...menu, isOpen: !menu.isOpen };
        } else {
          return { ...menu, isOpen: false }; // Close all other submenus
        }
      })
    );
  };
  
  return (
    <div>
      {/*  Sidebar  */}
      <div className="h-screen flex items-end justify-start bg-white-200 fixed top-0 left-0 z-20" >
        <div className={` ${open ? 'w-80 px-2 ' : 'w-16 hover:w-16'}  bg-white  h-screen sticky duration-400 transition-[width]`} >  {/* //lg:w-80  */}
          <div className={`justify-center mt-3 ${!open && 'w-16 overflow-hidden' }`}>
              {!open ?  <img src={iCompass_icon_crop} className='m-auto w-3/4 duration-200'/> : <img src={iCompass_icon} className='m-auto w-3/4 duration-200 '/> }
          </div>

        {/* Role-unique Tabs */}
        <ul className="pt-2">
          { decodedToken?.role == 'admin' && (
            <Link to='/admin'>
              <li className={`flex  rounded-md p-2 cursor-pointer hover:text-green-400 text-black items-center gap-x-4 ${Menu.gap ? 'mt-9' : 'mt-2'}   ${!open && 'invisible' }`} key='b22' >
                <BsPersonFillGear  /> Admins 
              </li>
            </Link>
          )}

          { decodedToken?.role == 'superAdmin' && (
            <Link to='/super' >
              <li className={`flex  rounded-md p-2 cursor-pointer hover:text-green-400 text-black items-center gap-x-4 ${Menu.gap ? 'mt-9' : 'mt-2'}   ${!open && 'invisible' }`} key='c22' >
                <BsPersonFillGear  /> Companies 
              </li>
            </Link>
          )}
          { decodedToken?.role == 'superAdmin' && (
            <Link to='/super/kyc' >
              <li className={`flex  rounded-md p-2 cursor-pointer hover:text-green-400 text-black items-center gap-x-4 ${Menu.gap ? 'mt-9' : 'mt-2'}   ${!open && 'invisible' }`} key='d22' >
                <BsWallet   /> KYC Billing 
              </li>
            </Link>
          )}



          {/* Subscriptions-unique Tabs */}
        <div>
          <div>
            {Menu.map((Menu, index) => (
              decodedToken?.subscriptions[Menu?.code] && (
              <>
                <li onClick={() => setSubMenuOpen(index)} key={`${index} aa`} className={`flex  rounded-md p-2 cursor-pointer hover:text-green-400  text-black text-base items-center gap-x-4 ${Menu.gap ? 'mt-9' : 'mt-2'} `} >
                  {Menu.icon}
                  <span className={`flex-1 ${!open && 'invisible' }`}>
                    {Menu.title}
                  </span>
                  {Menu.subMenus && ( <BsChevronDown className={`${Menu.isOpen && 'rotate-180'}`} /> )}
                </li>
                {Menu.subMenus && Menu.isOpen && open && (
                  <ul>
                    {Menu.subMenus.map((subMenuItem, idx) => (
                        <Link to={subMenuItem.src} replace={currentLocation.pathname === subMenuItem.src}>
                          <li key={`${idx} bb`} className={`flex px-5 cursor-pointer text-center text-black-200 py-1`}>  
                            <BsChevronDown className='-rotate-90 mt-1 mr-1'/>
                            {subMenuItem.title}
                          </li>
                      </Link>
                    ))}
                  </ul>
                )}
              </>
              ))
            )}
          </div>

          <div className='text-base fixed z-50 bottom-[100px] left-2  rounded-full drop-shadow-lg flex justify-center items-center text-white duration-300 flex-col'>
            <a href='https://docs.google.com/forms/d/e/1FAIpQLSfxH4cA8iGlVRstA4SO_NE91t-bAS92UnQASdss2QrZ1CoU-w/viewform' target="_blank">
              <li className={`flex  rounded-md p-2 cursor-pointer hover:text-green-400  mr-3 text-black items-center gap-x-4 ${Menu.gap ? 'mt-9' : 'mt-2'}   ${!open && 'invisible' }`} key='b23' >
                  <BsFillPatchQuestionFill />  Feedback 
              </li>
            </a>

            <Link to={'/change-log'} replace={currentLocation.pathname === '/change-log'}>
              <li className={`flex rounded-md p-2 cursor-pointer hover:text-green-400  text-black items-center gap-x-4 ${Menu.gap ? 'mt-9' : 'mt-2'}   ${!open && 'invisible' }`} key='b24' >
                  <BsClockHistory  />  Change log 
              </li>
            </Link>
          </div>
        </div>
      </ul>
    </div>
  </div>

          {/* Bottom adjustable icon */}
          <button onClick={() => setOpen(!open)} className="fixed z-50 bottom-10 left-5 bg-green-200 w-10 h-10 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-green-400 duration-300">
        <span className="text-white">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6 m-auto" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z" />
          </svg>
        </span>
      </button>
    </div>
  );
};

export default SideBar;