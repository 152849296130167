// import SignatureCanvas from 'react-signature-canvas';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getkyc, updatekyc, uploadFile, sendEmailNotification, frontEndBaseURL, createNotification , generateFormToken } from "../../../API/Api"
import { toast } from 'react-toastify';
import FormStatusEnum from '../../../data/FormStatusEnum';
import CountryList from './CountryList';
import ConnectedOrBeneficialParty from './ConnectedOrBeneficialParty'; 
import Tooltip from '@mui/material/Tooltip'; 
import { jwtDecode } from "jwt-decode";
import { DateTime } from 'luxon';
import Modal from "../../shared/Modal";
import { TokenContext } from '../../../App';

const _EntityDetails = ({ companyName, showSubmitButton , readOnly, currentUrl, clientFormId, maxPage, token, formAuthority }) => {
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [mandatoryFieldError, setMandatoryFieldError] = useState({
    name: '', uenNumber: '', incorporationCountry: '', incorporationDate: '', registeredAddress : '', contactNumber: '', email: '', taxResidenceCountry: 'SG', natureOfBusiness : '',
  });  
  const [mandatoryConnectedFieldError, setMandatoryConnectedFieldError] = useState([]) // name: '', address: '', identificationNumber: ''
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);


  const [formData, setFormData] = useState({
    _id : clientFormId, name: '', uenNumber: '', incorporationCountry: '', incorporationDate: '', registeredAddress : '', contactNumber: '', email: '', formStatus: '',
    connectedParty: [], 
    // [{
    //   relationship: '', name: '', gender: '', address: '', identificationNumber: '', identificationDocumentURL: '', identificationExpiryDate: '', 
    //   birthDate: '', address: '', email: '', residenceStatus: '', nationality: '',
    // 
    //   pepPerson: ' - No', pepPersonCountryOrOrganisation: '', pepPersonPublicFunctionNature: '', pepPersonRoleFrom: '', pepPersonRoleTo: '',  identificationDocumentLink: '', identificationExpiryDate: '',  
    //   pepAssociate: ' - No', pepAssociateRelationship: '',  pepAssociateName: '', pepAssociateCountryOrOrganisation: '', pepAssociatePublicFunctionNature: '',pepAssociateRoleFrom: '', pepAssociateRoleTo: '',
    //   pepFamily: ' - No', pepFamilyRelationship: '', pepFamilyName: '', pepFamilyCountryOrOrganisation: '', pepFamilyPublicFunctionNature: '', pepFamilyRoleFrom: '', pepFamilyRoleTo: '',
    // }],
    singaporeGovernmentEntity: '', listedOnStockExchange:'', financialInstitution:'', incumbencyCertificateUrl:'', companyConstitution:'', organisationChart:'',
    natureOfBusiness : '', sourceOfWealth : '', taxResidenceCountry: 'SG', declarationName: '', declarationDate: '', signatureUrl: '', accountManager: ''
  });  

    // const [encodedAccessToken] = useContext(TokenContext) 
    const decodedToken = jwtDecode(token)

  // let signatureRef = useRef(); 
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {  
      console.log('userData')
        const userData = await getkyc(clientFormId, token) 
        console.log(userData)
        console.log('userData3')
        setFormData({ 
          ...userData.data?.details,
          taxResidenceCountry: userData.data?.details?.taxResidenceCountry || "SG", 
          incorporationCountry: userData.data?.details?.incorporationCountry || "SG", 
          _id : clientFormId,
          name: userData?.data?.name,
          email : userData?.data?.email,
          formStatus : userData?.data?.formStatus,
          maxPage: userData?.data?.maxPage,
          connectedParty: userData?.data?.details?.connectedParty || [], 
        }) 

        userData.data?.connectedParty?.forEach( party => {
          mandatoryConnectedFieldError.append({name: '', address: '', identificationNumber: ''})
        })
        setMandatoryConnectedFieldError(mandatoryConnectedFieldError)
        
      }
      console.log('userData2')

      fetchData()
  }, []);  
 

  const handleFileUpload = async (e, fileType) => {
    const { name } = e.target;
    const file = e.target.files[0];
    if (!file) { return }

    try {
      const data = new FormData();
      data.append("file", file);
      const filename = `${fileType}_${file.name}`;
 
      const res = await uploadFile(data, filename, token); 
      if (res) { 
        setFormData(prevState => ({
          ...prevState,
          [name] : res.data.url
          // identificationDocumentLink :  res.data.url // name refer to 'input name' property.
      }))

      } else {
        toast.error("Invalid file type!") 
      }

    } catch (e) {
      toast.error("Invalid file type!")
    }
  }
 

  const handleFileRemove = async (fileName) => {
    if (readOnly) { return } 
    
    setFormData(prevState => ({
      ...prevState,
      [fileName] : ''
    }))
  } 


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newData = {
      ...formData,
      [name] : value // name refer to 'input name' property.
    }
    setFormData(newData) 

    
    const requiredFields = ["name", "uenNumber", "incorporationCountry", "incorporationDate", "registeredAddress",  "email", "taxResidenceCountry", "natureOfBusiness"];
    // if (decodedToken.companyId == 'Inspro_Insurance') {  requiredFields.push("contactNumber") } 
    if (!requiredFields.includes(name)) { return }

    let newMandatoryFieldError = { ...mandatoryFieldError };
    if (value.trim() === "") { newMandatoryFieldError[name] = true }
    else { newMandatoryFieldError[name] = false }

    setMandatoryFieldError(newMandatoryFieldError);
  }
  

  const handleSaveForm = async (e) => { 
    // let signatureData = await signatureRef.current.toDataURL(); // Use the signature data (base64 image) as needed 
    const newFormData = {
      ...formData,  
      maxPage : formData.maxPage || 1
    } 

    // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]r+\.[a-zA-Z]{2,}$/;
    // setIsValidEmail(emailPattern.test(formData.email));  // Check if the entered email matches the email pattern
    // for (const key in formData) {
    //       if (formData[key].trim() === '') {
    //           toast.info("Please fill in all fields")
    //           return 
    //       }
    // }

    setFormData(newFormData)
    toast.info("Form Saved!")

    await updatekyc(newFormData,  queryParams.get('formToken'))
    // if (currentUrl !== '/form/kyc-aml') {
    //   navigate('/kyc-aml/customer-list')
    // }  
  }   

  const handleSendEmail = async () => { 
    const formTokenData = {
      name: formData.name,
      userId : clientFormId,
      companyId : decodedToken.companyId,
    }

    const formToken = (await generateFormToken(formTokenData)).data.accessToken  
    const linkToForm = "https://" + frontEndBaseURL + '/form/kyc-aml?clientFormId=' + clientFormId + '&page=1&formToken=' + formToken;
 
    sendEmailNotification({
      email: formData.email,
      subject: "KYC Form Invitation - iCompass",
      message: `
        <div style="max-width: 600px; margin: 0 auto; border: 2px solid #FF8C00; padding: 20px; text-align: center;">
          <h1 style="margin-bottom: 20px; text-decoration: underline;">KYC Information Request</h1>
          <p> Dear ${formData.name} (Entity) </p>
          <p> ${decodedToken.name} from ${decodedToken.companyName} has requested for you to fill in your details in order to fulfill their KYC/AML obligations.  </p>
          <p> This link is only valid for 72 hours. </p>
          <p> 
            Please use the <a href= '${linkToForm} '> link </a>
            to access the form and fill in the necessary information as requested.
          </p>
          <img src="https://icompass-backend-dev-fhagh5hccpf2ebh9.southeastasia-01.azurewebsites.net/images/from_icompass" alt="iCOMPASS logo" style="display: block; margin: 20px auto; width: 200px; height: auto; max-width: 100%;">
          <p style="font-style: italic; margin-top: 20px;">Please do not reply to this email</p>
        </div> `
    })

     const newFormData = {
      ...formData,  
      emailSent : true,
      maxPage : 1
    } 
    setFormData(newFormData)
    await updatekyc(newFormData,  queryParams.get('formToken'))
    toast.info(`Form saved, Email notification to ${formData.email} sent!`)
    navigate('/kyc-aml/customer-list')

  }

  const handleSubmitForm = async (e) => { 
    // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // setIsValidEmail(emailPattern.test(formData.email));  // Check if the entered email matches the email pattern
    //   for (const key in formData) {
    //         if (formData[key].trim() === '') {
    //             toast.info("Please fill in all fields")
    //             return 
    //         }
    // }

    //1. check mandatory fields filled
    const requiredFields = ["name", "uenNumber", "incorporationCountry", "incorporationDate", "registeredAddress", "email", "taxResidenceCountry", "natureOfBusiness"];
    // if (decodedToken.companyId == 'Inspro_Insurance') {  requiredFields.push("contactNumber") } 
    
    let allRequiredFieldsFilled = true
    let newMandatoryFieldError = {...mandatoryFieldError}

    requiredFields.forEach( field => {
      if (!formData[field]) { 
        allRequiredFieldsFilled = false
        newMandatoryFieldError[field] = true 
      } else {
        newMandatoryFieldError[field] = false 
      }
    })
    setMandatoryFieldError(newMandatoryFieldError)

    const requiredConnectedFields = ['name', 'address' , 'identificationNumber']
    let newMandatoryConnectedFieldError = []

    formData.connectedParty.forEach((party, partyIndex) => {
      newMandatoryConnectedFieldError.push({ name: '', address: '', identificationNumber: '' })
      requiredConnectedFields.forEach((field) => {
        if (!party[field]) { 
          allRequiredFieldsFilled = false;
          newMandatoryConnectedFieldError[partyIndex][field] = true; 
        }
      });
    });
    setMandatoryConnectedFieldError(newMandatoryConnectedFieldError)

    if (!allRequiredFieldsFilled) {
        toast.info("Please fill in the required fields");
        return;
    }

    // 2. save to DB
    const newFormData = {
      ...formData,  
      formStatus : FormStatusEnum.PENDING_RISK_ASSESSMENT_2,  
      maxPage : 2
    } 
    setFormData(newFormData)
    toast.info("Form Submitted!")
    console.log('pre-update')
    await updatekyc(newFormData,  queryParams.get('formToken'))

    console.log('pre-notification')
    await createNotification({
      type: 'KYC',
      text: `${newFormData.name} status has changed to Pending Risk Assessment`,
      date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
  })
  console.log('post-notification')

    if (currentUrl !== '/form/kyc-aml') {
      window.location.href = `/kyc-aml/form?clientFormId=${clientFormId}&page=2`;
      // navigate('/kyc-aml/customer-list')
    } else {
      window.location.reload();
    }
  }
  console.log(formAuthority)
  console.log('formAuthority')

  return (
  <div className='max-w-5xl mx-auto'>
    <div className="max-w-6xl mx-auto mt-3 p-5 bg-white rounded-lg shadow-lg">
      <h2 className="text-xl font-semibold mb-1">KYC Form - {companyName} (Entity) </h2>
      {/* <h2 className="text-xl font-semibold mb-1">KYC Form - {companyName} (Entity, {formAuthority}) </h2> -> ADHOC TEMP REMOVAL OF MAS DISPLAY (.formAuthority), TO UNCOMMENT AND REMOVE THE ABOVE LINE */}
    </div>

    <div className={`max-w-6xl mx-auto mt-3 p-5 rounded-lg shadow-lg ${readOnly ? 'bg-gray-200' : 'bg-white '}`}>
    {/* 1st row */}
    <div className="grid grid-cols-2 gap-4 mb-4">
      {/* <input maxLength={800} disabled={readOnly}Field type='text' name='entityName' displayName='Name of Entity' value={formData.entityName} handleInputChange={handleInputChange} /> */}
        <div>
          <label className="block font-medium text-gray-700 mb-1">Name of Entity<i className='text-red-600'>*</i></label>
          <input maxLength={800} disabled={readOnly} type="text" name='name' placeholder="Enter Entity Name" value={formData.name} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
          {mandatoryFieldError.name && <p style={{ color: 'red' }}>Please enter a name</p>}
        </div>
        <div>
          <label className="block font-medium text-gray-700 mb-1">UEN Number / Company Identification Number<i className='text-red-600'>*</i></label>
          <input maxLength={800} disabled={readOnly} type="text" id="uenNumber" name='uenNumber' placeholder="Enter UEN Number / Company Identification Number" value={formData.uenNumber} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
          {mandatoryFieldError.uenNumber && <p style={{ color: 'red' }}>Please enter UEN Number</p>}
        </div>
      </div>
      {/* 2nd row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <p> Country of Incorporation </p>
          <CountryList width={'w-11/12'} value={formData.incorporationCountry} name='incorporationCountry' handleInputChange={handleInputChange} disabled={readOnly} />
        </div>
        <div>
          <label htmlFor="incorporationDate" className="block font-medium text-gray-700 mb-1"> Date of Incorporation<i className='text-red-600'>*</i></label>
          <input maxLength={800} disabled={readOnly} type="date" id="incorporationDate" value={formData.incorporationDate} name='incorporationDate' onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
          {mandatoryFieldError.incorporationDate && <p style={{ color: 'red' }}>Please enter incorporation date</p>}
        </div>
      </div>
      {/* 3rd row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block font-medium text-gray-700 mb-1">Registered Address<i className='text-red-600'>*</i></label>
          <input maxLength={800} disabled={readOnly} type="text" id="registeredAddress" name='registeredAddress' placeholder="Enter Registered Address" value={formData.registeredAddress} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
          {mandatoryFieldError.registeredAddress && <p style={{ color: 'red' }}>Please enter registered address</p>}
        </div>
        <div>
          <p> Country of Tax Residence</p>
          <CountryList width={'w-11/12'} value={formData.taxResidenceCountry} name='taxResidenceCountry' handleInputChange={handleInputChange} disabled={readOnly}/>
        </div>
      </div>
      {/* 4th row */}
      <div className="grid grid-cols-2 gap-4 mb-4"> 
        <div>
          <label htmlFor="email" className="block font-medium text-gray-700 mb-1">Email</label>
          <input maxLength={800} disabled={readOnly} type="email" id="email" placeholder="Enter Email" value={formData.email} onChange={handleInputChange} name="email" className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
          {mandatoryFieldError.email && <p style={{ color: 'red' }}>Please enter a valid email address</p>}
        </div>
        { decodedToken.companyId == 'Inspro_Insurance' && (
          <div>
            <label htmlFor="contactNumber" className="block font-medium text-gray-700 mb-1">Contact Number </label>
            <input maxLength={800} disabled={readOnly} type="tel" id="contactNumber" placeholder="Enter Contact Number" name="contactNumber"  value={formData.contactNumber} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
            {mandatoryFieldError.contactNumber && <p style={{ color: 'red' }}>Please enter contact number</p>}
          </div>
        )}
      </div>
      {/* 5th row */}
      <div className="grid gap-4 mb-5 border-green-500 border p-2">
        <div>
          <p className="block font-medium text-gray-700  py-1 px-2 underline">All relevant parties </p>
          <ConnectedOrBeneficialParty token={token} mandatoryConnectedFieldError={mandatoryConnectedFieldError} setMandatoryConnectedFieldError={setMandatoryConnectedFieldError} disabled={readOnly}  personType="Relevant Party" formName="connectedParty" formData={formData} setFormData={setFormData} handleInputChange={handleInputChange} isValidEmail={isValidEmail} setIsValidEmail={setIsValidEmail} borderColor={"green"} />
        </div> 
      </div>

      {/* 6th Row */}
      <div className="grid gap-4">
      </div>

      {/* 7th row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <div className='flex'> 
            <span className='mr-2'> Source of Wealth </span>   
            <Modal title="Definitions" token={token} CustomButton={() => 
              <Tooltip 
                title={ <p> Click to View definitions </p> } arrow
                componentsProps={{
                  tooltip: {
                    sx: { maxWidth: "600px", fontSize: "14px" },
                  },
                }}
              >
                <span className='text-xl relative bottom-0.5' > ⓘ </span> 
              </Tooltip>
            }>
              <SourceOfWealthFunds />       
            </Modal>
          </div>
          <textarea maxLength={800} disabled={readOnly} name='sourceOfWealth' placeholder="Enter Source of Wealth" value={formData?.sourceOfWealth} onChange={handleInputChange} className="w-11/12 mb-3 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" rows={3}/>  

          Nature of Business (Principal activities in ACRA)<i className='text-red-600'>*</i>
          <NatureOfBusiness formData={formData} handleInputChange={handleInputChange} disabled={readOnly}/> 
          {mandatoryFieldError?.natureOfBusiness && <p style={{ color: 'red' }}>Please enter nature of business</p>}

        </div>
            
        <div>
          <span className='mr-2'> Source of Funds </span>   
          <textarea maxLength={800} disabled={readOnly} name='sourceOfFunds' placeholder="Enter Source of Funds" value={formData?.sourceOfFunds} onChange={handleInputChange} className="w-11/12 mb-3 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" rows={3}/>
          
          { decodedToken.companyId == 'Inspro_Insurance' && ( 
            <>
              <label htmlFor="Account Manager" className="block font-medium text-gray-700 mb-1">Account Manager</label>
              <input maxLength={800} disabled={readOnly} id="Account Manager" placeholder="Enter Account Manager" name="accountManager"  value={formData?.accountManager} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
            </> 
          )}


          {formData?.natureOfBusiness?.includes('Others') && (
            <div>
              Others - Nature of Business
              <input maxLength={800} disabled={readOnly} placeholder="Describe Nature of Business" name="otherBusinessNature"  value={formData?.otherBusinessNature} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
            </div>
          )}

        </div>

      </div> 


      

      {/* 8th row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block font-medium text-gray-700 mb-1">Are you a Singapore Government Entity?</label>
            <div className="mt-1 flex items-center">
              <input maxLength={800} disabled={readOnly} type="radio" name="singaporeGovernmentEntity" value="yes" onChange={handleInputChange} checked={formData?.singaporeGovernmentEntity === 'yes'} className="mr-1 w-4 h-4" />
               Yes
              <input maxLength={800} disabled={readOnly} type="radio" name="singaporeGovernmentEntity" value="no" onChange={handleInputChange} checked={formData?.singaporeGovernmentEntity === 'no'} className="ml-2 mr-1 w-4 h-4"/>
               No
            </div>
            {/* <div> another col </div> */} 
          </div>
          
        <div>
          <label className="block font-medium text-gray-700 mb-1">Are you a listed entity?</label>
          <div className="mt-1 flex items-center">
            <input maxLength={800} disabled={readOnly} type="radio" id="pepYes" name="listedOnStockExchange" value="yes" onChange={handleInputChange} checked={formData.listedOnStockExchange === 'yes'} className="mr-1 w-4 h-4" />
             Yes
            <input maxLength={800} disabled={readOnly} type="radio" id="pepNo" name="listedOnStockExchange" value="no" onChange={handleInputChange} checked={formData.listedOnStockExchange === 'no'} className="ml-2 mr-1 w-4 h-4"/>
             No
          </div>
        </div>
      </div>
      {/* 9th row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block font-medium text-gray-700 mb-1">Are you a financial institution?</label>
            <div className="mt-1 flex items-center">
              <input maxLength={800} disabled={readOnly} type="radio" name="financialInstitution" value="yes" onChange={handleInputChange} checked={formData.financialInstitution === 'yes'} className="mr-1 w-4 h-4" />
               Yes
              <input maxLength={800} disabled={readOnly} type="radio"name="financialInstitution" value="no" onChange={handleInputChange} checked={formData.financialInstitution === 'no'} className="ml-2 mr-1 w-4 h-4"/>
               No
            </div>
          </div>
          <div>
            <div>
              <p> Certificate of Incumbency </p>
              {formData?.incumbencyCertificateUrl && (
                <>
                 <a href={`${process.env.REACT_APP_BACKEND_URL}/get-upload?fileName=${formData.incumbencyCertificateUrl.split('/').slice(-1)[0]}&accessToken=${token}`} target='_blank'  rel="noopener noreferrer" className='text-blue-600 mr-2'> 
                  {decodeURIComponent(formData?.incumbencyCertificateUrl.split('/').slice(-1)[0])}
                </a> 
                 <span className='cursor-pointer' onClick={() => handleFileRemove('incumbencyCertificateUrl')}> ❌ </span> 
                 </>
                )}
              <label className="cursor-pointer bg-gray-200 px-1 py-0.5 mt-1 rounded">
                Browse
                <input maxLength={800} disabled={readOnly} type="file" name="incumbencyCertificateUrl" onChange={(e) => handleFileUpload(e, 'incumbencyCertificateUrl')} className="hidden"/>
              </label>
            </div>
          </div>
        </div>
        {/* 10th row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <p> Company Constitution </p>
          {formData?.companyConstitution && (
            <>
              <a href={`${process.env.REACT_APP_BACKEND_URL}/get-upload?fileName=${formData.companyConstitution.split('/').slice(-1)[0]}&accessToken=${token}`} target='_blank'  rel="noopener noreferrer" className='text-blue-600 mr-2'> 
              {decodeURIComponent(formData?.companyConstitution.split('/').slice(-1)[0])} 
            </a> 
              <span className='cursor-pointer' onClick={() => handleFileRemove('companyConstitution')}> ❌ </span> 
            </>
          )}
          <label className="cursor-pointer bg-gray-200 px-1 py-0.5 mt-1 rounded">
            Browse
            <input maxLength={800} disabled={readOnly} type="file"  name="companyConstitution" onChange={(e) => handleFileUpload(e, 'CompanyConstitutionLink')} className="hidden"/>
          </label>
        </div>
        <div>
          <p> Other Document (Eg Organisation Chart, ACRA) </p>
          {formData?.organisationChart && (
            <>
             <a href={`${process.env.REACT_APP_BACKEND_URL}/get-upload?fileName=${formData.organisationChart.split('/').slice(-1)[0]}&accessToken=${token}`} target='_blank'  rel="noopener noreferrer" className='text-blue-600 mr-2'> 
              {decodeURIComponent(formData?.organisationChart.split('/').slice(-1)[0])} 
            </a> 
             <span className='cursor-pointer' onClick={() => handleFileRemove('organisationChart')}> ❌ </span> 
            </>
             )}
        <label className="cursor-pointer bg-gray-200 px-1 py-0.5 mt-1 rounded">
          Browse
          <input maxLength={800} disabled={readOnly} type="file" name="organisationChart" onChange={(e) => handleFileUpload(e, 'OrganizationChartLink')} className="hidden"/>
        </label>
        </div>
      </div>


      <br/><hr/><br/>
      { currentUrl !== '/form/kyc-aml' && !readOnly && (
        <div className='my-4 flex justify-between'>
          <button onClick={handleSaveForm} disabled={readOnly} className='ml-4 bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600'>
            Save Form 
          </button>

          { showSubmitButton && (
          <p>
            <Tooltip title={ <p> This sends an email with a url to view this form in an external page, to the email field listed above. <br/> After Sending Notification Email, this form can only be submitted via the external form link.</p>  }
              arrow
              componentsProps={{ 
                tooltip: {
                  sx: { maxWidth: "445px", fontSize: "14px" },
                },
              }}
            > 
            <span className='text-xl relative bottom-0.5' > ⓘ </span> 
            </Tooltip>

            <button onClick={handleSendEmail} disabled={readOnly} className='ml-4 bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600'>
              Send Notification Email
            </button> 
  
            { !formData?.emailSent ? (
              <button onClick={handleSubmitForm} disabled={readOnly} className='ml-4 bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600'>
                Submit
              </button>  
            ) : (
              <Tooltip title={<p style={{ fontSize: "15px" }}>Email Notification sent, awaiting customer submission.</p>} arrow className='ml-4 bg-green-300 text-white font-semibold py-2 px-4 rounded-md '>
                <button onClick={handleSubmitForm} disabled={true}  className='ml-4'> Submit </button>  
              </Tooltip> 
              )
            }

          </p>
          )}
        </div>
        )
      }

      {currentUrl === '/form/kyc-aml' && !readOnly && (
        <div className='my-4 flex justify-between'>
            <button onClick={handleSaveForm} className='ml-4 bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600'> 
              Save Form
            </button>
          { showSubmitButton && (
            <p> 
              <button onClick={handleSubmitForm} className='ml-4 bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600'> 
                Submit
              </button>
            </p>
          )}
      </div>
      )}

    </div> <br/> <br/> <br/>
</div>
  );
};
export default _EntityDetails;



const SourceOfWealthFunds = () => {
  return (
    <div className='p-6 w-6xl'>
      <p>
        <strong> Source of wealth: </strong> Origin of the customer’s/beneficial owner’s entire body of wealth (total assets) and how it was acquired. Although you may not have information about assets that are not deposited/processed by you, 
        it may be possible to obtain information from the customer, commercial databases or other open sources (evidence of title, copies of trust deeds, audited accounts, salary details, tax returns, bank statements etc.)
      </p>
      <br/> <hr/> <br/>
      <p>
        <strong> Source of funds: </strong> Origin of the funds or other assets which are the subject of the establishment of business relations (amount invested in/transferred to you etc.). You should not limit source of funds inquiry to the direct source
        from which the funds have been transferred, but also the activity that generated the funds. The information should be substantive and facilitate the establishment of the provenance of the funds (Salary payment, sale proceeds etc.)
      </p>
    </div>
  )
}





const NatureOfBusiness = ({formData, handleInputChange,disabled }) => {
  return (
  <Tooltip title={formData?.natureOfBusiness?.split(' - ')[1]} leaveDelay={700} arrow>
    <select disabled={disabled} value={formData?.natureOfBusiness} onChange={handleInputChange} name='natureOfBusiness'className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-1 focus:border-green-500">
      <option value=""> - Please Select - </option>
      <option value="Medium-Low - Accountants">Accountants</option>
      <option value="Low - Approved Trustees">Approved Trustees</option>
      <option value="High - Banks">Banks</option>
      <option value="Medium-Low - Broker Dealers and Corporate Finance Advisory Firms">Broker Dealers and Corporate Finance Advisory Firms</option>
      <option value="Medium-High - Casinos">Casinos</option>
      <option value="Medium-High - Corporate Services Providers (CSPs)">Corporate Services Providers (CSPs)</option>
      <option value="Low - Direct Life & Composite Insurers">Direct Life & Composite Insurers</option>
      <option value="Medium-High - DPTSPs">DPTSPs (Digital payment token service providers) </option>
      <option value="Medium-High - External Asset Managers (EAMs)">External Asset Managers (EAMs)</option>
      <option value="Low - Finance Companies">Finance Companies</option>
      <option value="Low - Financial Advisers">Financial Advisers</option>
      <option value="Medium-Low - Fund Management Companies (excluding External Asset Managers)">Fund Management Companies (excluding External Asset Managers)</option>
      <option value="Low - Insurance Brokers">Insurance Brokers</option>
      <option value="Medium-High - Licensed Trust Companies (LTCs)">Licensed Trust Companies (LTCs)</option>
      <option value="Medium-Low - Lawyers">Lawyers</option>
      <option value="Medium-Low - Money Changers">Money Changers</option>
      <option value="Medium-Low - Moneylenders">Moneylenders</option>
      <option value="Low - Non-Bank Credit Card Companies">Non-Bank Credit Card Companies</option>
      <option value="Medium-High - Payment Institutions WITH Cross Border Money Transfer Services">Payment Institutions WITH Cross Border Money Transfer Services</option>
      <option value="Medium-Low - Payment Institutions WITHOUT Cross Border Money Transfers">Payment Institutions WITHOUT Cross Border Money Transfers</option>
      <option value="Low - Pawnbrokers">Pawnbrokers</option>
      <option value="Medium-High - Precious Stones and Precious Metals Dealers">Precious Stones and Precious Metals Dealers</option>
      <option value="Medium-High - Real Estate Agents/Developers">Real Estate Agents/Developers</option>
      <option value="Low - Securities Depository">Securities Depository</option>
      <option value="Low - Others">Others</option>
    </select>
  </Tooltip>
  )
} 
