import axios from "axios"; 

export const frontEndBaseURL = process.env.REACT_APP_FRONTEND_URL
export const API_public = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true // this allows cookies to be set for res.cookie(...), AND for Frontend to send that cookie as well.
 });

API_public.interceptors.response.use(
  (response) => {
    return response; // Continue with the response
  },
  (error) => { 
    console.log(error?.message)
    if (error.response.status === 429) {
      alert("Too many requests, please try again later.") 
      return Promise.resolve()
    }
    return Promise.reject(error); // For other errors, reject the promise
  }
);

export const API = axios.create({ 
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true  
});

API.interceptors.response.use(
  (response) => {
    return response; // Continue with the response
  },
  (error) => {
    console.info("Error intercepted", error?.message);
    console.log("Request URL:", error.config?.url);
    console.log("Request Method:", error.config?.method);

    if (error.config?.url == '/user/refresh-token') { 
      return Promise.resolve()
    }

    else if (error.response.status === 403 || error.response.status === 401) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken') 
      logoutUser().then(() => { 
        window.location.href = '/login'; // Redirect to login or home page
        return Promise.reject(new Error("Unauthorized, redirecting to login."))
      })
      
    }

    else if (error.response.status === 429) {
      alert("Too many requests, please try again later.") 
      return Promise.reject(new Error("Too many requests, try again later."))
    }

    else if (error.response.status === 400) {
      alert("Allowed Input length exceeded! Please enter a shorter input or contact the Admin.") 
      return Promise.reject(new Error("Input length exceeded."))
    }

    return Promise.resolve(); // For other errors, reject the promise
  }
);

function getAccessToken() { return '' } // No longer needed, jwt stored in res.cookie from backend.
// function getAccessToken() { return localStorage.getItem('accessToken') } // get latest token at call

// ---------------- login/reset password ------------------------
export const loginUser = (obj) => API_public.post(`/user/login`, obj);
export const logoutUser = () => API_public.post(`/user/logout`);
export const getNewAccessAndRefreshToken = (refreshToken) => API.post(`/user/refresh-token`, { headers: { 'Authorization': `Bearer ${refreshToken}` } }); 

export const updateUserOtp = (obj) => API_public.put(`/user/otp`, obj);  
export const verifyOtp = (obj) => API_public.post(`/user/verify-otp`, obj );
export const updateForgetPassword = (obj) => API_public.put(`/user/reset-password`, obj);
export const updatePassword = (obj, token) => API.put(`/user/update-password`, obj,  { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }) 


// ---------------- Admin ------------------------ 
export const getAllUsers = (token) => API.get(`/user/all`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const createUser = (obj, token) => API.post(`/user/create-user`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const updateUser = (obj, token) => API.put(`/user/update-user`, obj , { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const deleteUser = (id, token) => API.delete(`/user/delete-user?id=${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });


// ---------------- Profile ------------------------ 
export const updateCompanyDetails = (obj, token) => API.put(`/user/update-company`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }); // have to manually create for the first time
export const getUserById = (id, token) => API.get(`/user/getUserById?id=${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const getCompanyDetails = (type, token) => API.get(`/user/company?type=${type}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });


// ---------------- SuperAdmin ------------------------ 
export const getAllCompanyAndUsers = (token) => API.get(`/superadmin/company`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const createCompany = (obj, token) => API.post(`/superadmin/company`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const updateCompany = (companyId, token) => API.put(`/superadmin/company?companyId=${companyId}`, null, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const deleteCompany = (companyId, token) => API.delete(`/superadmin/kyc-screening-interval`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });

export const getAllCompanyKycDetails = (token) => API.get(`/superadmin/kyc-billing`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const updateCompanyKycDetails = (companyId, token) => API.put(`/superadmin/kyc-billing?companyId=${companyId}`, null, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });

// ---------------- KYC-AML module ------------------------ 
export const getkyc = (clientFormId, formToken) => API.get(`/kyc/client?clientFormId=${clientFormId}&formToken=${formToken}`);  
export const getkycAll = (companyId, token) => API.get(`/kyc/client/all?companyId=${companyId}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const getkycPDF = (userId, token) => API.get(`/kyc/client/pdf?userId=${userId}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` }, responseType: 'blob' });  
export const getKycFormToCsv = (userId, token) => API.get(`/kyc/client/csv?userId=${userId}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` }, responseType: 'blob' });

export const createkyc = (obj, token) => API.post(`/kyc/client`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const updatekyc = (data, token) => API.put(`/kyc/client`, data, { headers: { 'Authorization': `Bearer ${token}` } }); // not getLocalStorage, because client form view has no localStorage
export const updatekycStatus = (data, token) => API.put(`/kyc/client-status`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const deletekyc = (userId, token) => API.delete(`/kyc/client/?userId=${userId}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });

export const getkycRiskAssessment = (userId, token) => API.get(`/kyc/risk?userId=${userId}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const getkycCustomerPastRisk = (customerId, token) => API.get(`/kyc/risk/all?customerId=${customerId}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
// above 2 is identical lol. return list of assessments??
export const getKycAllClientsRisks = (token) => API.get(`/kyc/risk/all-clients`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });

export const createkycRiskAssessment = (obj, token) => API.post(`/kyc/risk`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const updatekycRiskAssessment = (obj, token) => API.put(`/kyc/risk`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const createKycComment = (obj, token) => API.post(`/kyc/risk/comment`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const createKycNestedSanctionComment = (obj, token) => API.post(`/kyc/risk/sanction_comment`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });

export const getKycBilling = (token) => API.get(`/kyc/bill`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const updateKycBillingLog = (obj) => API.post(`/kyc/bill`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const updateKycBillingAlert = (obj) => API.put(`/kyc/bill`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const getRescreeningFrequency = (token) => API.get(`/kyc/frequency`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });

export const sanctionIoHits = (obj) => API.post(`/sanctions/hits`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const worldCheckHits = (obj) => API.post(`/worldcheck/hits`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });

// ---------------- CMP module ------------------------ 
export const getCmpTopic = (topicId, token) => API.get(`/cmp/topic?topicId=${topicId}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const getCmpAllTopics = (token) => API.get(`/cmp/alltopics`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const createCmpTopic = (obj, token) => API.post(`/cmp/topic`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const updateCmpTopic = (obj, topicId, token) => API.put(`/cmp/topic?topicId=${topicId}`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const deleteCmpTopic = (topicId, token) => API.delete(`/cmp/topic?topicId=${topicId}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });

export const getCmpForm = (formId, token) => API.get(`/cmp/form?formId=${formId}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const getCmpAllForms = (token) => API.get(`/cmp/allforms`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const createCmpForm = (obj, token) => API.post(`/cmp/form`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const updateCmpForm = (obj, token) => API.put(`/cmp/form`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const deleteCmpForm = (formId, token) => API.delete(`/cmp/form?formId=${formId}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const archiveCmpForm = (formId, token) => API.delete(`/cmp/archive-form?formId=${formId}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });

export const getCmpFormToCsv = (formId, token) => API.get(`/cmp/form-to-csv?formId=${formId}`, {   headers: { 'Authorization': `Bearer ${getAccessToken()}` },   responseType: 'arraybuffer' });
export const getCmpFormToLogs = (formId, token) => API.get(`/cmp/form-to-logs?formId=${formId}`, {   headers: { 'Authorization': `Bearer ${getAccessToken()}` },   responseType: 'arraybuffer' });

// ---------------- notifications ------------------------ 
export const getAllNotifications = (module, token) => API.get(`/notification/getAll?moduleType=${module}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const createNotification = (obj, token) => API.post(`/notification/create`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const sendEmailNotification = (obj) => API.post(`/notification/email`, obj); // to auth only requests from FE, have to use CORS



// ---------------- shared ------------------------ 
export const generateFormToken = (tokenData, token) => API.post(`/user/formtoken`, tokenData, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } });
export const uploadFile = (obj, filename, token) => API.post(`/upload?filename=${filename}&formToken=${token}`, obj, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }); 
  // export const sendEmailNotification = (obj) => API.post(`/notification/send`, obj).then(() => {
  // PNotify.success({
  //   title: 'Notification sent'
  //   }
  // })



export const generatePDF = (obj) => API.post(`/generatePDF`, obj, { responseType: 'blob' });
export const generateXLSX = (obj) => API.post(`/generateXLSX`, obj, { responseType: 'blob' });
export const downloadXLSXTemplate = () => API.get(`/downloadXLSXTemplate`, { responseType: 'blob' });
export const downloadFPTemplate = () => API.get(`/downloadFPTemplate`, { responseType: 'blob' });
export const downloadKYCTemplate = () => API.get(`/downloadKYCTemplate`, { responseType: 'blob' });