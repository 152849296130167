import React, { useState, useEffect } from 'react';
import { RightContainer, Card, Row } from "../../shared/utils"
import { getKycBilling, updateKycBillingLog, getRescreeningFrequency, updateKycBillingAlert } from "../../../API/Api" 
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';

const _KycAmlTranscationHistory = () => {
  const [loading, setLoading] = useState(true)
  const [newCreditWarning, setNewCreditWarning] = useState()
  const [creditBalance, setCreditBalance] = useState()
  const [pastTransactions, setPastTransactions] = useState([])
  const [rescreeningFrequency, setRescreeningFrequency] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allBilling = await getKycBilling();
        const balance = allBilling.data.find(log => log?.transactionType === "balance");
        const transactions = allBilling.data.filter(log => log?.transactionType !== "balance");
        setNewCreditWarning(balance.creditWarning)
        setCreditBalance(balance);
        setPastTransactions(transactions); 

        const rescreening = await getRescreeningFrequency()
        setRescreeningFrequency(rescreening.data)

      } catch (error) {
        console.error('Error fetching billing data:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);


  const handleUpdateCreditWarning = async () => {
    try {
      await updateKycBillingAlert({newCreditWarning})
      toast.success('Credit limit warning updated!')
    } catch(e) {
      toast.error('Something went wrong...')
      console.log('err updating limit', e)
    }
  }

  const getCreditChangeLabel = (creditChangeValue) => {
    if (creditChangeValue > 0) { 
      return <div className='bg-green-300 p-1'> +{creditChangeValue} </div>
    } else {
      return <div> {creditChangeValue} </div>
    }
   }



   const handleCreditChange = async (amount, transactionType) => {
    try {
      const mockData = 
      {
        "kycaml_client_id": "client12345",
        "nameScreened": "John Doe (individual)", // does not mention connected party of entitites.
        "screeningType": "individual",
        "creatorEmail": "admin@company.com",
        "transactionDate": DateTime.now().toFormat("dd-MM-yyyy HH:mm"),
        "creditChange": amount,
        "transactionType": transactionType
        // "balanceBefore": 800,
      }
      await updateKycBillingLog(mockData)
    } catch(e) {
      toast.error('Something went wrong...')
      console.log('err making transcation')
    }
   }



 
  if (loading) { return <div> Loading ... </div>}

  return (
      <div> 
        <Card> <h2 className="text-lg font-semibold">KYC/AML Transaction History</h2> </Card>
 
        <h2 className="text-lg font-bold  p-4  mx-2 bg-gray-300 "> Credits and Frequency </h2>
        <Card className="overflow-auto h-20 flex justify-between">
          <p> Last Top up: {creditBalance?.lastTopUpDate} </p> 
          <p> Credits left: {creditBalance?.currentCredits} </p>
          <p> Credit Warning limit: {creditBalance?.creditWarning} </p>
          <p> Rescreeening Frequency: {rescreeningFrequency?.companyHighRiskRescreeningFrequency} Days</p>
          <p> Rescreeening Frequency: {rescreeningFrequency?.companyStandardRiskRescreeningFrequency} Days</p>
        </Card>


        <h2 className="text-lg font-bold  p-4 mx-2 bg-gray-300"> Past API calls </h2>
        <Card className='h-fit'> 
          { pastTransactions.map( transaction => (
            <div className='flex justify-between'>
                <p> {transaction.nameScreened } </p>
                <p> {getCreditChangeLabel(transaction?.creditChange) } </p>  
                <p> {transaction?.balanceBefore || '' } </p>
                {/* <p> {transaction?.balanceAfter } </p> */}
                <p> {transaction?.transactionDate } </p>
            </div>
          ))}
          {/*  make it a paginated table using MUI. */}
          {/* Filter to show top ups only */}
          {/* Filter to show deductions only */}
          {/* Extra, search by date range */}
        </Card>

        <h2 className="text-lg font-bold mt-9 p-4 mx-2 bg-gray-300"> Mock Control Plane <span className='text-md font-normal'> (refresh page to see changes) </span> </h2>
        <Card className='flex'>
          <p className='mx-6 flex'> 
            <span className='mt-2'> Set credit warning limit </span>
            <input type='number' name='creditWarning' value={newCreditWarning} onChange={(e)=> (setNewCreditWarning(e.target.value))} className="w-3/12 px-1.5 mx-3 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
            <button onClick={handleUpdateCreditWarning} className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded-sm"> Update Limit </button>
          </p>
          <p className='mx-6'> 
            <button onClick={() => handleCreditChange(-200, 'deduction')} className="bg-green-400 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg"> 
              Decrement 200
            </button>
          </p>
          <p className='mx-6'> 
            <button onClick={() => handleCreditChange(1000, 'increment')} className="bg-green-400 hover:bg-green-600 text-white font-bold py-2 px-4 mr-4 rounded-lg"> 
              Top-up 1000 
            </button>
            
          </p>
        </Card>
    </div>
  );
};

export default _KycAmlTranscationHistory;
