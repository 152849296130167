import React from 'react';
import Tooltip from '@mui/material/Tooltip'; 

const CustomTooltip = ({ title }) => {
    return (
        <Tooltip 
            title={ <p>{title}</p> } arrow
            componentsProps={{
                tooltip: {
                sx: { maxWidth: "600px", fontSize: "14px" },
                },
            }}
            >
            <span className="text-xl relative" style={{ top: "1px" }}> ⓘ </span>
        </Tooltip>
    )
}

export default CustomTooltip;
