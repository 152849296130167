import React, { useState, useContext } from 'react';
import { downloadKYCTemplate, createkyc, createNotification } from "../../../API/Api"
import readXlsxFile from 'read-excel-file'
import FormStatusEnum from '../../../data/FormStatusEnum';
import { jwtDecode } from "jwt-decode";
import { DateTime } from 'luxon';
import { TokenContext } from '../../../App';
import { toast } from 'react-toastify';

const AddUserModalContent = ({handleCloseModal, setLoading}) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [newUserData, setNewUserData] = useState({
    username: '',
    email : '',
    formType : 'individual',
    formAuthority: 'MAS'
  });
   
  const [encodedAccessToken] = useContext(TokenContext) 
  const decodedToken = jwtDecode(encodedAccessToken)
  const notify = () => toast.success("User(s) successfully added!") 


  const getKYCTemplate = async () => {
    let response = await downloadKYCTemplate();
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);    // Create a temporary URL for the blob

    const link = document.createElement('a'); // Create a link element to trigger the file download
    link.href = url;
    link.download = 'kyc_template.xlsx'; // Set the desired file name
    link.click();
    window.URL.revokeObjectURL(url); // Clean up the URL object
}


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) { setUploadedFile(file) }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    setSubmitted(true)
    const userCompanyId = decodedToken.companyId

    // a) bulk upload
    if (uploadedFile) {
        try {
            const fileData = await readXlsxFile(uploadedFile)
            fileData.slice(1).map(async (row) => {
                const userData = {
                    name: row[0] || '',
                    email: row[1] || '',
                    type: row[2], //TODO, if row2 empty, do not accept
                    // formAuthority: row[3],
                    formStatus: FormStatusEnum.PENDING_CLIENT_DETAILS_1,
                    archived: false,
                    companyId: userCompanyId,
                    updatedDate: new Date().toISOString(),
                    submitedDate: new Date().toISOString(),
                    maxPage: 1
                }
                await createkyc(userData)
            })
            setLoading(true) // have to trigger re-fetch from DB cuz need the added objectId

            await createNotification({
                type: "KYC",
                text: `${fileData.slice(1).length} Persons has been added`,
                date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
            })
            notify()   

        } catch(err) {
            console.error('An error occurred')
            toast.error('Something went wrong...')
        }

    // b) single creation 
    } else {  

        for (const key in newUserData) {
          if (newUserData[key].trim() === '') { 
            toast.info("Please fill in all fields");
            setSubmitted(false)
            return; // Prevent form submission
          }}
          
            const userData = {
                name: newUserData.username,
                email: newUserData.email,
                type: newUserData.formType,
                formAuthority: newUserData.formAuthority,
                updatedDate: new Date().toISOString(),
                submitedDate: new Date().toISOString(),
                archived: false,
                formStatus: FormStatusEnum.PENDING_CLIENT_DETAILS_1,
                companyId: userCompanyId,
                maxPage: 1,
              }
            await createkyc(userData)
            createNotification({
                type: 'KYC',
                text: `A new person, ${newUserData.username}, has been added`,
                date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
            })

            setLoading(true)  
            notify()  
    }
    handleCloseModal()
  }
 
  const handleRemoveFile = () => { setUploadedFile(null) };


  const handleInputChange = (e) => {
    setNewUserData(prevState => ({
        ...prevState,
        [e.target.name] : e.target.value // name refer to 'input name' property.
    }))
  }


  return (
    <div className="w-full max-w-md mx-auto bg-white rounded-md shadow-lg p-4">
      <form onSubmit={handleSubmit}>
        <div className="mb-4 flex">
            <div className="w-1/2 ">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1"> Name: </label>
                <input maxLength={800} type="text" id="name" name="username" placeholder="Enter Name"
                        className="border border-gray-300 rounded-md p-2 w-full" onChange={handleInputChange}
                        value={newUserData.username}/>
            </div>
            <div className="w-1/2 mx-6">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1"> Email: </label>
                <input maxLength={800} type="text" id="email" name="email" placeholder="Enter Email"
                        className="border border-gray-300 rounded-md p-2 w-full" onChange={handleInputChange}
                        value={newUserData.email}/>
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="formType" className="block text-sm font-medium text-gray-700 mb-1"> Form Type: </label>
            <select id="formType" name="formType" className="border border-gray-300 rounded-md p-2 w-full" onChange={handleInputChange} value={newUserData.formType}>
              <option value="individual">Individual Form</option>
              <option value="entity">Entity Form</option>
            </select>
          </div>

          <div className="mb-4">
            {process.env?.REACT_APP_PRODUCTION !== 'production' && (
              <select  name="formAuthority" defaultValue='MAS' className="border border-gray-300 rounded-md p-2 w-full" onChange={handleInputChange} value={newUserData.formAuthority}>
                <option value="MAS">MAS</option>
                <option value="ACRA">ACRA</option>
              </select>
            )}

          </div>


        <div className="mb-4">
          <label htmlFor="fileInput" className="sr-only">Upload Excel Sheet</label>
          <input maxLength={800} type="file" id="fileInput" name="fileInput" accept=".xlsx,.xls" onChange={handleFileChange} className="border border-gray-300 rounded-md p-2 w-full hidden"/>
        </div>


        {!uploadedFile ? ( <br/>)  :
          <div className="text-gray-700 mb-2">
            Uploaded File:
            <p> {uploadedFile.name}  <span className='cursor-pointer' onClick={handleRemoveFile}> ❌ </span> </p>
          </div>}

        <div className="flex items-center justify-between">
          <label htmlFor="fileInput" className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-md cursor-pointer"> Upload Excel  </label>
          <span className="text-gray-500 cursor-pointer hover:text-green-600" onClick={getKYCTemplate}>Download Template</span>
          <button type="submit" disabled={submitted}  className={`bg-green-500 text-white font-semibold py-2 px-4 rounded-md ${submitted ? 'cursor-not-allowed opacity-50' : 'hover:bg-green-600'}`}>
            Submit 
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddUserModalContent;
