import React, { useState, useEffect, useContext, useCallback } from 'react';
import { uploadFile, updatekyc , worldCheckHits , sanctionIoHits, createNotification, createKycComment, getkycRiskAssessment, createKycNestedSanctionComment, sendEmailNotification, getkyc, getkycAll, updatekycStatus,  createkycRiskAssessment, updatekycRiskAssessment, getAllUsers, frontEndBaseURL} from "../../../API/Api" 
import { Card } from '../../shared/utils';
import QuestionCard from './QuestionCard'; 
// import FormStatusEnum from '../../../data/FormStatusEnum'
import { DateTime } from "luxon"
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {ListB, ListC, ListD, highRiskJurisdictions, transparencyInternational } from '../../../data/RiskCountries'
import SanctionsScreeningSection from './SanctionsScreeningSection'
import SubmissionCard from './SubmissionCard';
import { jwtDecode } from "jwt-decode";
import { TokenContext
  
 } from '../../../App';

const _RiskAssessment = ({ clientFormId, userType, readOnly, currentUrl, formAuthority}) => {
  const [encodedAccessToken] = useContext(TokenContext) 
  const decodedToken = jwtDecode(encodedAccessToken)

  const [formData, setFormData] = useState({
    kycaml_client_id: clientFormId, formAuthority: formAuthority, screenedDate: '', submittedBy: '', reviewerReviewEmail: [],
    sourceOfWealthDocumentURL: '', otherDocumentsURL: '', managementSupportDocumentURL: '',
    screenedDate: '', nextRescreeningDate: '', status: 'new',
    overrideRiskLevel: '', overrideRiskSelected: false,
    riskLevel: '', //work around to show default value, to fix
    // riskLevel: 'Incomplete questions!', screenedDate: '', nextRescreeningDate: '', status: 'new', //   // status: 'yet to be decided by reviewer, aka pending_admin_review',

    singaporeGovernmentEntity: '', listedOnStockExchange: '', singaporeFI: '', nonSingaporeFI: '', 
    politicallyExposedPerson: '', countryCountermeasureFATF: '', countryInadequateAML_CFT: '', 
    higherRiskSector: '', highRiskJurisdictionFATF: '', credibleBodyIdentifiedCountry: '',
    
    investmentVehicle : { response: '', justification: '', newComment:'', comments:[] }, // comment object [ {name: '', content: '', time: '', id: ''} ]
    customerPensionSuperannuation: { response: '', justification: '', newComment:'', comments:[]},  
    unverifiedBusinessActivity: { response: '', justification: '', newComment:'', comments:[] },  
    unverifiedCorporateStructure: { response: '', justification: '', newComment:'', comments:[] },  
    unverifiedFinancialActivity: { response: '', justification: '', newComment:'', comments:[] },  
    unusualOwnershipStructure: { response: '', justification: '', newComment:'', comments:[] }, 
    unusualBusinessCircumstances: { response: '', justification: '', newComment:'', comments:[] }, 
    nomineeShareholdersBearer: { response: '', justification: '', newComment:'', comments:[] }, 
    anonymousTranscations: { response: '', justification: '', newComment:'', comments:[] }, 
    unknownPaymentSources: { response: '', justification: '', newComment:'', comments:[] }, 

    pepHits:[], 
    //   nameTitle: '', positionStart: 'NA', positionEnd: 'NA',  si_identifier: '',  
    //   answer: '', justification: '', comments:[], newComment // just reference it, save as empty string if needed.
    crimesHits:[],
    // nameDataSource: '', remarks: '',  // name: '', dataSource: '', remarks: '', 
    // answer: '', justification: '', comments:[] // comment object [ {name: '', content: '', time: '', id: ''} ]
    sanctionsHits:[],
    // name: '', dataSource: '', 
    // answer: '', justification: '', comments:[]
    highRiskJurisdictionHits:[],
    // name: '', dataSource: '', 
    // answer: '', justification: '', comments:[]
    adverseNewsHits:[],
    // title: '', description: '', url: '',
    // answer: '', justification: '', comments:[]
    screeningSource: 'sanctions.io'
  }); 
  const [kycData, setKycData] = useState(); 
  const [loading, setLoading] = useState(true);
  const [allreviewers, setAllreviewers] = useState([])
  const userRole = decodedToken.role
  
  useEffect(() => {
    const fetchData = async () => {

      try {
        let allreviewers = (await getAllUsers()).data
        allreviewers = allreviewers.filter(users => users.role !== 'preparer' && users.role !== 'viewer');
        setAllreviewers(allreviewers) 

        const currentKycData = await getkyc(clientFormId).then(response => response.data) 
        setKycData(currentKycData)

        const kycRiskDataList = await getkycRiskAssessment(clientFormId).then(response => response.data);  
        let kycRiskData = kycRiskDataList[0] // verify if this indeed returns the oldest one? // so far seems ok.

        if (!kycRiskDataList.length == 0) { 
          setFormData(kycRiskData)
          setLoading(false);
          return
        }


        toast.info(`Assessment running...`);
        // 1. Run automated checks and create entry
        const emptyFormData = automatedChecks(clientFormId, currentKycData)
        const creationDate = DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss")  
        kycRiskData = {
          ...emptyFormData, 
          screenedDate: creationDate         
        } 
        await createkycRiskAssessment(kycRiskData)

        // 2. Call sanctions.io API and update entry 

        if ( ['500_Global'].includes(decodedToken.companyId) ) { //worldCheck hits
          const payload = { 
            // apiKey, apiSecret, 
            screenedName:currentKycData['name'],
            connectedParty:currentKycData?.details?.connectedParty,
            entityType:currentKycData['type'],
            kycaml_client_id : currentKycData['_id'],
            screenedDate : creationDate,
            screeningSource: 'worldCheck'
           }
          
          const worldCheckResponse = await worldCheckHits(payload)

          if (worldCheckResponse.data.message == 'ERR_BAD_REQUEST') { 
            toast.error('Invalid worldCheck API key/secret!') 
            return
          }
          else {
            const {pepHits, crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits} = worldCheckResponse.data
            setFormData({...formData, pepHits,crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits, screeningSource: 'worldCheck'})
            checkRiskStatus({...kycRiskData, pepHits, crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits })
          }

        }
        else { // Sanctions.io hits
          const payload = { 
            screenedName:currentKycData['name'],
            connectedParty:currentKycData?.details?.connectedParty,
            entityType:currentKycData['type'],
            kycaml_client_id : currentKycData['_id'],
            screenedDate : creationDate,
            screeningSource: 'sanctions.io' 
          }
          const {pepHits, crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits} = (await sanctionIoHits(payload)).data
          const updatedData = {...kycRiskData, pepHits, crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits, screeningSource: 'sanctions.io'}
          setFormData(updatedData)
          checkRiskStatus(updatedData)
        }

        toast.success(`Assessment complete!`);
        setLoading(false);
    
      } catch(e) {
        console.error(e)
        toast.error(`Something went wrong...`);
      } 
    } 
  
    fetchData()
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newFormData = {  // instead of setState, prevData => {...prevData, new: newValue}
      ...formData,         // do this, to ensure is kept up to date
      [name] : value  
    }

    setFormData(newFormData) 
    checkRiskStatus(newFormData)
  }

  const handleNestedInputChange = (e, nestedName) => {
    const { name, value } = e.target; 

    const newFormData = {  // instead of setState, prevData => {...prevData, new: newValue}
      ...formData,         // do this, to ensure is kept up to date
      [name] : {
        ...formData[name],
        [nestedName] : value
      }  
    }
    setFormData(newFormData) 
    checkRiskStatus(newFormData)
  }

  const handleRunAssessmentAgain = async (e) => {
    try {
      setLoading(true)
      toast.info(`Assessment running...`);

      const currentKycData = await getkyc(clientFormId).then(response => response.data) 
      setKycData(currentKycData)
      
      // 1. Run automated checks and create entry
      const emptyFormData = automatedChecks(clientFormId, currentKycData)
      const currentScreenedDate = formData.screenedDate;
      const creationDate = DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss") 

      const kycRiskData = {
        ...emptyFormData, 
        screenedDate: creationDate,
        currentScreenedDate    
      } 
      await updatekycRiskAssessment(kycRiskData)

      // 2. Call sanctions.io API and update entry 
      if ( ['500_Global'].includes(decodedToken.companyId) ) {
        const payload = { 
          // apiKey, apiSecret, 
          screenedName:currentKycData['name'],
          connectedParty:currentKycData?.details?.connectedParty,
          entityType:currentKycData['type'],
          kycaml_client_id : currentKycData['_id'],
          screenedDate : creationDate,
          screeningSource: 'worldCheck'
         }
        
        const worldCheckResponse = await worldCheckHits(payload)

        if (worldCheckResponse.data.message == 'ERR_BAD_REQUEST') { 
          toast.error('Invalid worldCheck API key/secret!') 
          return
        }
        else {
          const {pepHits, crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits} = worldCheckResponse.data
          setFormData({...formData, pepHits,crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits, screeningSource: 'worldCheck'})
          checkRiskStatus({...kycRiskData, pepHits, crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits })
        }
      }
      else {
        const payload = { 
          screenedName:currentKycData['name'],
          connectedParty:currentKycData?.details?.connectedParty,
          entityType:currentKycData['type'],
          kycaml_client_id : currentKycData['_id'],
          screenedDate : creationDate,
          screeningSource: 'sanctions.io' 
        }
        const {pepHits, crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits} = (await sanctionIoHits(payload)).data
        const updatedData = {...kycRiskData, pepHits,crimesHits, sanctionsHits, highRiskJurisdictionHits, adverseNewsHits, screeningSource: 'sanctions.io'}
        setFormData(updatedData)
        checkRiskStatus(updatedData)
      }
      
      toast.success(`Assessment complete!`);
      setLoading(false);
     
    } catch(e) {
      console.error(e)
      toast.error(`Something went wrong...`);
    }

  }


  const handleSubmitComment = async (e, nestedName, inputName) => {
    if (formData[inputName][nestedName].trim() == '') { return }

    const newCommentEntry = {
      screenedDate: formData.screenedDate,
      kycaml_client_id: clientFormId,
      question: inputName,
      content : formData[inputName][nestedName],
      name : decodedToken.name,
      commented_at : DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
      comment_id : uuidv4() //random ID, for future if allow deletion
    }
    await createKycComment(newCommentEntry)  
    formData[inputName].comments.push(newCommentEntry);

    setFormData((prevState) => ({
      ...prevState,
      [inputName]: {
          ...prevState[inputName],  // Keep existing nested object properties
          [nestedName]: formData[inputName].comments,     // .push should be on its own satement, not assignment
          // [nestedName]: [...formData[inputName].comments, newCommentEntry],   // push mutates the array, so runs double
          newComment: ''   
      }
  }));

  }

  const handleSaveForm = async (e) => {   
    updatekycRiskAssessment(formData) 
    toast.success(`Assessment saved!`);
  }   

  // const checkFormValidity = () => {
  //   const isValid = Object.values(formData).every(value => value.trim() !== '');

  //   if ()
  //   setIsFormValid(isValid);
  // };

  const handleSubmitForReview = async (e) => { 
    let newFormData = {
      ...formData,
      status: 'waiting for review', 
      submitPerson : decodedToken.name,
      submitDate : DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
    }

    // submittedBy
    await updatekycRiskAssessment(newFormData)
    updatekycStatus({formStatus: "pending_reviewer_review", id: clientFormId })
    toast.success(`Submitted for review!`);
    
    sendEmailNotification({
      email: formData.reviewerReviewEmail,
      subject: "KYC Risk Assessment Form pending review - iCompass",
      message: `
        <div style="max-width: 600px; margin: 0 auto; border: 2px solid #FF8C00; padding: 20px; text-align: center;">
          <h1 style="margin-bottom: 20px; text-decoration: underline;"> Risk Assessment Approval Pending</h1>
          <p>${decodedToken.name} has submitted a risk assessment form, ${kycData.name} (${kycData.type}), for your approval. 
            <a href='${window.location.href}'> Click to view form </a>
          </p>
          <img src="https://icompass-backend-dev-fhagh5hccpf2ebh9.southeastasia-01.azurewebsites.net/images/from_icompass" alt="iCOMPASS logo" style="display: block; margin: 20px auto; width: 200px; height: auto; max-width: 100%;">
          <p style="font-style: italic; margin-top: 20px;">Please do not reply to this email</p>
        </div> `
    })

    setFormData(newFormData)
    
    createNotification({
      type: 'KYC',
      text: `${kycData.name} status has changed to Pending reviewer Review`,
      date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
  })
  }  


  const handleFileChange = async (e, fileType) => {
    const file = e.target.files[0];
    const {name} = e.target
    if (!file) { return }

    try {
      const data = new FormData();
      data.append("file", file);
      const filename = `${file.name}`;
      const res = await uploadFile(data, filename); 

      if (res) { 
        setFormData(prevState => ({
          ...prevState,
          [name] : res.data.url
          // identificationDocumentLink :  res.data.url // name refer to 'input name' property.
      }))

      } else {
        toast.error("Invalid file type!") 
      }



    } catch (e) {
      toast.error(`Invalid file type!`);
    }
  }

  const checkRiskStatus = (newFormData) => { 
    console.log(newFormData)
    console.log('newFormData')
    // 1. if all no, AND SDD > 0, risk = 'low'
    const SDD_Questions = ['singaporeGovernmentEntity', 'listedOnStockExchange', 'singaporeFI', 'nonSingaporeFI', 'investmentVehicle', 'customerPensionSuperannuation']
    let SSD_yes_count = 0 


    SDD_Questions.forEach(SSD_qn_key => {      
      if (newFormData[SSD_qn_key] == 'Yes' || newFormData[SSD_qn_key]?.response == 'Yes' ) { SSD_yes_count++ }   
    })  
    
    // 2a. if EDD > 0, risk = 'high' instantly, even if justified
    const EDD_Questions = ['politicallyExposedPerson', 'countryCountermeasureFATF', 'countryInadequateAML_CFT', 'unverifiedBusinessActivity', 'unverifiedCorporateStructure', 'unverifiedFinancialActivity']
    let EDD_yes_count = 0 

    EDD_Questions.forEach(EDD_qn_key => {       
      if (newFormData[EDD_qn_key]?.response == 'Yes' || newFormData[EDD_qn_key] == 'Yes') { EDD_yes_count++ }
    })

    // 2b. if Yes high, else justified = medium
    const EDD_Hits_Questions = ['pepHits', 'crimesHits', 'sanctionsHits', 'highRiskJurisdictionHits', 'adverseNewsHits']
    let EDD_Hits_yes_count_Justified = 0
    let EDD_Hits_yes_count_NotJustified = 0
    
    EDD_Hits_Questions.forEach(EDD_qn_justification_key => {
      newFormData[EDD_qn_justification_key].forEach(hit => {
        if (hit?.response === 'Yes' && hit?.justification.trim() == '') { 
          EDD_Hits_yes_count_NotJustified++ 
        } else if (hit?.response === 'Yes' && hit?.justification.trim() !== '') { 
          EDD_Hits_yes_count_Justified++ 
        }
      })
    })

    // 3a. if AA > 0, risk = 'Medium', "High risk if yes and unjustified. but medium risk if justified"
    const AA_Questions = ['unusualOwnershipStructure', 'unusualBusinessCircumstances', 'nomineeShareholdersBearer', 'anonymousTranscations', 'unknownPaymentSources']
    let AA_yes_count_Justified = 0
    let AA_yes_count_NotJustified = 0
 
    AA_Questions.forEach(AA_qn_key => {
      if (newFormData[AA_qn_key]?.response === 'Yes' && newFormData[AA_qn_key]?.justification.trim() == '') { 
        AA_yes_count_NotJustified++ 
      } else if (newFormData[AA_qn_key]?.response === 'Yes' && newFormData[AA_qn_key]?.justification.trim() !== '') { 
        AA_yes_count_Justified++ 
      }
    })

    // 3b. if AA_automated > 0 , Risk = 'High',      
    const AA_automated_Questions = ['higherRiskSector', 'highRiskJurisdictionFATF', 'credibleBodyIdentifiedCountry' ]
    let AA_automated_yes_count = 0

    AA_automated_Questions.forEach(AA_automated_qn_key => { 
      if (newFormData[AA_automated_qn_key]?.response == 'Yes' || newFormData[AA_automated_qn_key] == 'Yes') { AA_automated_yes_count++ }
    }) 

    //4. Check if all questions have been answered
    let individualQuestions = ['politicallyExposedPerson', 'countryCountermeasureFATF', 'countryInadequateAML_CFT', 'unusualBusinessCircumstances', 'highRiskJurisdictionFATF', 'credibleBodyIdentifiedCountry']
    const entityQuestions = ['singaporeGovernmentEntity', 'listedOnStockExchange', 'singaporeFI', 'nonSingaporeFI', 'investmentVehicle', 'customerPensionSuperannuation', 'unverifiedBusinessActivity', 'unverifiedCorporateStructure', 'unverifiedFinancialActivity', 'higherRiskSector', 'unusualOwnershipStructure', 'nomineeShareholdersBearer', 'anonymousTranscations', 'unknownPaymentSources' ]
    if (userType == 'entity') { individualQuestions = [...individualQuestions, ...entityQuestions] }

    const allQuestionsAnswered = individualQuestions.every((question_key) => {
      const value = newFormData[question_key];
      if (typeof value === 'object') {
        return value?.response?.trim() // If value is an object of: {response: '', justification: '', newComment: '', comments: []} , check if 'response' exists and is non-empty
      }
      return typeof value === 'string' && value.trim(); // If value is a string, eg FATF: 'Yes', check if it's non-empty
    });

    // 5. Determine risk level
    let riskLevel = 'Standard' 
    // let riskLevel = 'Incomplete Questions!'
    // if (!allQuestionsAnswered) {
    //   console.log('Not all questions answered')
    // }
    if (EDD_yes_count === 0 && EDD_Hits_yes_count_Justified == 0 && EDD_Hits_yes_count_NotJustified == 0
      && AA_automated_yes_count == 0 && AA_yes_count_Justified == 0 && AA_yes_count_NotJustified == 0
      && SSD_yes_count > 0) {
        riskLevel = 'Low'
      } 
    else if (EDD_yes_count > 0 || EDD_Hits_yes_count_NotJustified > 0 || AA_automated_yes_count > 0 || AA_yes_count_NotJustified > 0 ) {
        riskLevel = 'High';
    }  
    else if (EDD_yes_count === 0 && EDD_Hits_yes_count_Justified == 0 && EDD_Hits_yes_count_NotJustified == 0
      && AA_automated_yes_count == 0 && AA_yes_count_Justified == 0 && AA_yes_count_NotJustified == 0
      && SSD_yes_count == 0) {
        riskLevel = 'Standard'
    }
    else if (EDD_Hits_yes_count_Justified > 0 ||  AA_yes_count_Justified > 0) {
      riskLevel = 'Standard';
    }  
    else {
      riskLevel = "edge case!"
    }
    
    const updatedFormData = {
      ...newFormData,
      riskLevel : riskLevel  
    }
    setFormData(updatedFormData)
  }

  const getRiskTag = (risk) => {
    const riskClasses = {
      Low: 'text-green-500',
      Standard: 'text-orange-500',
      High: 'text-red-500',
      Edge: 'text-purple-600', 
    };
    const riskClass = riskClasses[risk] || riskClasses['Edge']; // Default to 'Edge' for incomplete question
    return <span className={`bg-gray-200 py-2 px-4 border border-b-gray-300 ${riskClass}`}>{risk}</span>;
  };

  const getStatusTag = (status) => {
    if (status == "new" || status == "waiting for review") {
      return <span className="text-orange-500"> Yet to be decided by reviewer</span> 
    }
    else if (status == 'accepted') {
      return <span className="text-green-500">Accepted</span> 
    }
    else if (status == 'rejected') {
      return <span className="text-red-500">Rejected</span> 
    } else {
      return <span className="text-purple-600">Edge case!!!</span> 
    }
  }
 

  const handleStatusUpdate = (e, updatedStatus) => { 
    // if (!allQuestionsAnswered) { 
    //   toast.error('Please answer all questions!')
    //   return
    //  } 

      let nextRescreeningDate = ''
      let onGoingMonitoring=''
      let currentDate = DateTime.now().setZone('Asia/Singapore');
      let maxPage = 2

      if (updatedStatus == 'accepted') {
        if (formData.riskLevel == 'Low') {  nextRescreeningDate = currentDate.plus({ years: 3 }).toFormat("dd-MM-yyyy") } 
        if (formData.riskLevel == 'Standard') {  nextRescreeningDate = currentDate.plus({ years: 2 }).toFormat("dd-MM-yyyy") } 
        if (formData.riskLevel == 'High') {  nextRescreeningDate = currentDate.plus({ years: 1 }).toFormat("dd-MM-yyyy") } 
        if (decodedToken.companyId == 'Inspro_Insurance') {  nextRescreeningDate = currentDate.plus({ years: 1 }).toFormat("dd-MM-yyyy") } 
        onGoingMonitoring = true
        maxPage = 3
      }
      const currentScreenedDate = formData.screenedDate;
      const newFormData = {
          ...formData,
          status : updatedStatus,
          screenedDate :  DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
          nextRescreeningDate: nextRescreeningDate,
          approvalPerson: decodedToken.name,
          approvalDate :  DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
          currentScreenedDate
        }

      setFormData(newFormData)
      updatekycStatus({
        formStatus: updatedStatus,
        id: clientFormId,
        nextRescreeningDate: nextRescreeningDate,
        onGoingMonitoring: onGoingMonitoring,
        maxPage: maxPage
       })

      updatekycRiskAssessment(newFormData)


      sendEmailNotification({
        email: kycData.email,
        subject: "KYC Risk Assessment Form status update - iCompass",
        message: `
          <div style="max-width: 600px; margin: 0 auto; border: 2px solid #FF8C00; padding: 20px; text-align: center;">
            <h1 style="margin-bottom: 20px; text-decoration: underline;"> Risk Assessment Status update</h1>
            <p>${decodedToken.name} has updated the status of risk assessment form for, ${kycData.name} (${kycData.type}) to ${updatedStatus}. 
              <a href='${window.location.href}'> Click to view form </a>
            </p>
            <img src="https://icompass-backend-dev-fhagh5hccpf2ebh9.southeastasia-01.azurewebsites.net/images/from_icompass" alt="iCOMPASS logo" style="display: block; margin: 20px auto; width: 200px; height: auto; max-width: 100%;">
            <p style="font-style: italic; margin-top: 20px;">Please do not reply to this email</p>
          </div> `
      })

      createNotification({
        type: 'KYC',
        text: `${kycData.name} status has changed to ${updatedStatus}`,
        date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
    })
  }

  let qnCounter = 1

  return (
<div className={`max-w-6xl mx-auto mt-3 p-5 rounded-lg shadow-lg ${readOnly ? 'bg-gray-200' : 'bg-white '}`}>
  {loading ? <div className='h-screen w-screen'> Loading... </div> : (
  <div> 
        
    <div className='flex justify-between'>
      <h1 className='font-semibold underline text-2xl m-2 mb-4'>KYC Form - Risk Assessment </h1>
      {/* <h1 className='font-semibold underline text-2xl m-2 mb-4'>KYC Form - Risk Assessment (MAS) </h1> -> ADHOC TEMP REMOVAL OF MAS DISPLAY (.formAuthority), TO UNCOMMENT AND REMOVE THE ABOVE LINE */}
      <button onClick={handleRunAssessmentAgain} disabled={readOnly} className={`self-center w-2/12 py-1 px-2 bg-green-500 hover:bg-green-600 text-white font-semibold rounded-lg ${readOnly ? 'opacity-30' : 'opacity-100'}`} >
           Run Assessment again
      </button>
    </div>

  {
    decodedToken?.kycSettings?.nameScreenOnly ? (
      // Name screen only clients from jwt nameScreenOnly
      <SanctionsScreeningSection qnCounter={qnCounter} readOnly={readOnly} formData={formData} kycData={kycData} 
        setFormData={setFormData} checkRiskStatus ={checkRiskStatus} decodedToken={decodedToken} clientFormId={clientFormId} />      
    ) : (
    <>
    {/* SSD  */}
    {userType == 'entity' && 
      <>
        <QuestionCard disabled={readOnly}  inputName={"singaporeGovernmentEntity"} handleInputChange={handleInputChange} question={"Is the customer a Singapore government entity?"} 
          automated={true} questionNumber={qnCounter++} justification={false} value={formData.singaporeGovernmentEntity}/>   
        <QuestionCard disabled={readOnly}  inputName={"listedOnStockExchange"} handleInputChange={handleInputChange} question={"Is the customer listed on the stock exchange and subject to regulatory disclosure requirements?"} 
          automated={true} questionNumber={qnCounter++} justification={false} value={formData.listedOnStockExchange}/>  
        <QuestionCard disabled={readOnly}  inputName={"singaporeFI"} handleInputChange={handleInputChange} question={<>Is the customer an FI incorporated or established <strong>in Singapore</strong> that is subject to and supervised for compliance with AML/CFT requirements consistent with FATF standards?</>} 
          automated={true} questionNumber={qnCounter++} justification={false} value={formData.singaporeFI}/>  
        <QuestionCard disabled={readOnly}  inputName={"nonSingaporeFI"} handleInputChange={handleInputChange} question={<> Is the customer an FI incorporated or established in a country <strong>outside of Singapore</strong> that is subject to and supervised for compliance with AML/CFT requirements consistent with FATF standards" </>} 
          automated={true} questionNumber={qnCounter++} justification={false} value={formData.nonSingaporeFI}/>  
        <QuestionCard disabled={readOnly}  inputName={"investmentVehicle"} handleInputChange={handleNestedInputChange} question={ "Is the customer an investment vehicle where the managers are financial institutions in Singapore or incorporated or established in a country outside of Singapore that is subject to and supervised for compliance with AML/CFT requirements consistent with FATF standards?"} 
          automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.investmentVehicle}/>  
        <QuestionCard disabled={readOnly}  inputName={"customerPensionSuperannuation"} handleInputChange={handleNestedInputChange} question={"Does the customer provide pension, superannuation or similar scheme that provides retirement benefits to employees, where contributions are made by way of deduction from wages, and the scheme rules do not permit the assignment of a member’s interest under the scheme?"} 
          automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.customerPensionSuperannuation}/>  
        <QuestionCard disabled={readOnly}  inputName={"unverifiedBusinessActivity"} handleInputChange={handleNestedInputChange} question={"Is the customer a legal person for which we are unable to establish if it has any ongoing, apparent or visible business activity?"} 
          automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.unverifiedBusinessActivity}/>  
        <QuestionCard disabled={readOnly}  inputName={"unverifiedCorporateStructure"} handleInputChange={handleNestedInputChange} question={"Is the customer a legal person for which we are unable to establish its business purpose for its corporate structure or arrangement?"} 
          automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.unverifiedCorporateStructure}/>  
        <QuestionCard disabled={readOnly}  inputName={"unverifiedFinancialActivity"} handleInputChange={handleNestedInputChange} question={"Is the customer a legal person where we are unable to establish if it has any substantive financial activity in its interaction with us?"} 
          automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.unverifiedFinancialActivity} tooltipTitle={"e.g. Whether the customer is a legal entity actively engaged in real business activities, like regular transactions and revenue generation."}/>
      </>
    }

        {/* EDD  */}
        <QuestionCard disabled={readOnly}  inputName={"politicallyExposedPerson"} handleInputChange={handleInputChange} question={"Is the customer or its beneficial owners politically exposed person, or a family member or close associate of a political exposed person?"} 
          automated={true} questionNumber={qnCounter++} justification={false} value={formData.politicallyExposedPerson}/>  
        <QuestionCard disabled={readOnly}  inputName={"countryCountermeasureFATF"} handleInputChange={handleInputChange} question={"Does the customer come from a country or jurisdiction in relation to which the FATF has called for countermeasures?"} 
          automated={true} questionNumber={qnCounter++} justification={false} value={formData.countryCountermeasureFATF}/>  
        <QuestionCard disabled={readOnly}  inputName={"countryInadequateAML_CFT"} handleInputChange={handleInputChange} question={"Does the customer or any beneficial owner come from a country or jurisdiction known to have inadequate AML/CFT measures, as determined by the foreign regulatory authorities or by the authority (MAS)?"} 
          automated={true} questionNumber={qnCounter++} justification={false} value={formData.countryInadequateAML_CFT}/>  
  
        {/* EDD qn13-17 */}
        <SanctionsScreeningSection qnCounter={qnCounter} readOnly={readOnly} formData={formData} kycData={kycData} 
          setFormData={setFormData} checkRiskStatus ={checkRiskStatus} decodedToken={decodedToken} clientFormId={clientFormId} />      

        {(() => { qnCounter += 5; return null; })()}

    {/* AA  */}
    {userType == 'entity' && 
      <>
        <QuestionCard disabled={readOnly}  inputName={"higherRiskSector"} handleInputChange={handleInputChange} question={"Is the customer from higher risk sectors identified in Singapore's NRA or by the CMI?"} 
          automated={true} questionNumber={qnCounter++} justification={false} value={formData.higherRiskSector}/>  
        <QuestionCard disabled={readOnly}  inputName={"unusualOwnershipStructure"} handleInputChange={handleNestedInputChange} question={"Is the ownership structure of the customer unusual or excessively complex given the nature of the business?"} 
          automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.unusualOwnershipStructure} tooltipTitle={"e.g. Complex ownership structures that can hide the true owners or controllers of a business, often involving multiple entities, offshore jurisdictions, trusts, and nominees shareholders."}/>  
        <QuestionCard disabled={readOnly}  inputName={"nomineeShareholdersBearer"} handleInputChange={handleNestedInputChange} question={"Does the customer have nominee shareholders or shares in bearer form?"} 
          automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.nomineeShareholdersBearer} tooltipTitle={"e.g. Is the individual or entity holding shares on behalf of the beneficial owner? Shares in bearer form are physical stock certificates that are not registered to any specific owners and ownership transfer can be done by handing over the certificate."}/>  
        <QuestionCard disabled={readOnly}  inputName={"anonymousTranscations"}handleInputChange={handleNestedInputChange} question={"Does the customer engage in anonymous transactions?"} 
          automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.anonymousTranscations} tooltipTitle={"e.g. Typically involve transactions where the customer’s personal details are not disclosed, often using methods like cash, certain cryptocurrencies, or prepaid cards."}/>  
        <QuestionCard disabled={readOnly}  inputName={"unknownPaymentSources"} handleInputChange={handleNestedInputChange} question={"Does the customer undertake frequent payments from unknown sources or unassociated third parties?"} 
          automated={false} questionNumber={qnCounter++}  justification={true} handleSubmitComment={handleSubmitComment} value={formData.unknownPaymentSources} tooltipTitle={"e.g. Origin of funds that is unclear, which can raise concerns about the legitimacy of the transactions."}/>  
      </> 
    }

      <QuestionCard disabled={readOnly}  inputName={"unusualBusinessCircumstances"} handleInputChange={handleNestedInputChange} question={"Is the business relations conducted under unusual circumstances? (Eg. significant unexplained geographic distance between the Company and Customer.)"} 
        automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.unusualBusinessCircumstances} tooltipTitle={"e.g. Significant unexplained geographic distance between the Company and Customer example, the customer is located far away from where the company operates, without a clear reason for this arrangement."}/>  
      <QuestionCard disabled={readOnly}  inputName={"highRiskJurisdictionFATF"} handleInputChange={handleInputChange} question={"Is the customer from countries or jurisdictions which have a higher level of corruption, organised crime or inadequate AML/CFT measures as identified by FATF?"} 
        automated={true} questionNumber={qnCounter++} justification={false} value={formData.highRiskJurisdictionFATF}/>  
      <QuestionCard disabled={readOnly}  inputName={"credibleBodyIdentifiedCountry"} handleInputChange={handleInputChange} question={"Is the customer from countries identified by credible bodies (Transparency International) as having significant levels of corruption, terrorism financing or other criminal activity?"} 
        automated={true} questionNumber={qnCounter++} justification={false} value={formData.credibleBodyIdentifiedCountry}/>  
    </>
  )}

    <Card>
      <p className='mb-2 font-semibold'> Outcome </p> <hr/>
      <p className='my-4'> The risk level is suggested to be: {getRiskTag(formData?.riskLevel)} </p>

      <SubmissionCard getRiskTag={getRiskTag} formData={formData} setFormData={setFormData} handleFileChange={handleFileChange} 
        getStatusTag={getStatusTag} handleStatusUpdate={handleStatusUpdate} handleSaveForm={handleSaveForm} 
        handleInputChange={handleInputChange} currentUrl={currentUrl} userRole={userRole}
        allreviewers={allreviewers} handleSubmitForReview={handleSubmitForReview} clientFormId={clientFormId}
        readOnly={readOnly}
        />
    </Card>

  </div>
  )}
</div>
  );
};


export default _RiskAssessment


function automatedChecks(clientFormId, currentKycData) {
  let emptyFormData = ({ kycaml_client_id: clientFormId, screenedDate: '', sourceOfWealthDocumentURL: '', otherDocumentsURL: '', riskLevel: 'Standard', nextRescreeningDate: '', 
    // let emptyFormData = ({ kycaml_client_id: clientFormId, screenedDate: '', sourceOfWealthDocumentURL: '', otherDocumentsURL: '', riskLevel: 'Incomplete questions!', nextRescreeningDate: '', 
    status: 'new', singaporeGovernmentEntity: 'No', listedOnStockExchange: 'No', singaporeFI: 'No', nonSingaporeFI: '', politicallyExposedPerson: '', countryCountermeasureFATF: '', countryInadequateAML_CFT: '', higherRiskSector: '', highRiskJurisdictionFATF: '', credibleBodyIdentifiedCountry: '', investmentVehicle : { response: '', justification: '', newComment:'', comments:[] }, customerPensionSuperannuation: { response: '', justification: '', newComment:'', comments:[]}, unverifiedBusinessActivity: { response: '', justification: '', newComment:'', comments:[] }, unverifiedCorporateStructure: { response: '', justification: '', newComment:'', comments:[] }, unverifiedFinancialActivity: { response: '', justification: '', newComment:'', comments:[] }, unusualOwnershipStructure: { response: '', justification: '', newComment:'', comments:[] }, unusualBusinessCircumstances: { response: '', justification: '', newComment:'', comments:[] }, nomineeShareholdersBearer: { response: '', justification: '', newComment:'', comments:[] }, anonymousTranscations: { response: '', justification: '', newComment:'', comments:[] }, unknownPaymentSources: { response: '', justification: '', newComment:'', comments:[] }, pepHits:[], crimesHits:[], sanctionsHits:[], highRiskJurisdictionHits:[], adverseNewsHits:[], screeningSource: 'sanctions.io'});
 
if (currentKycData.type == 'individual') {
  // qn1-4
    emptyFormData.singaporeGovernmentEntity  = 'No' 
    emptyFormData.listedOnStockExchange  = 'No'
    emptyFormData.singaporeFI  = 'No'
    emptyFormData.nonSingaporeFI  = 'No' // check ListD for entity

  // qn7-9

    if (currentKycData?.details?.pepAssociate == 'yes' || currentKycData?.details?.pepPerson == 'yes' || currentKycData?.details?.pepFamily == 'yes' ) {
      emptyFormData.politicallyExposedPerson = 'Yes'
    } else { emptyFormData.politicallyExposedPerson = 'No' }

    if (ListB.includes(currentKycData?.details?.nationality)) { //check List B
      emptyFormData.countryCountermeasureFATF  = 'Yes'
    } else { emptyFormData.countryCountermeasureFATF = 'No' }

    if (ListC.includes(currentKycData?.details?.nationality) ) { // Check List C
      emptyFormData.countryInadequateAML_CFT  = 'Yes'
    }  else { emptyFormData.countryInadequateAML_CFT = 'No' }


    // qn 18
    emptyFormData.higherRiskSector = 'No' 

    // qn 22, 23 
    if (highRiskJurisdictions.includes(currentKycData?.details?.nationality)) {
      emptyFormData.highRiskJurisdictionFATF  = 'Yes'
    } else { emptyFormData.highRiskJurisdictionFATF = 'No' }

    if (transparencyInternational.includes(currentKycData?.details?.nationality)) {
      emptyFormData.credibleBodyIdentifiedCountry  = 'Yes'
    }  else { emptyFormData.credibleBodyIdentifiedCountry = 'No' }
 
    return emptyFormData
  } 


  else if (currentKycData?.type == 'entity') {
    // qn1-4
    if (currentKycData?.details?.singaporeGovernmentEntity == 'yes' ) {
      emptyFormData.singaporeGovernmentEntity  = 'Yes'
    } else { emptyFormData.singaporeGovernmentEntity = 'No' }

    if (currentKycData?.details?.listedOnStockExchange == 'yes' ) {
      emptyFormData.listedOnStockExchange  = 'Yes'
    } else { emptyFormData.listedOnStockExchange = 'No' }

    if (currentKycData?.details?.financialInstitution == 'yes' && currentKycData?.details?.incorporationCountry == 'SG') {
      emptyFormData.singaporeFI  = 'Yes'
    } else { emptyFormData.singaporeFI = 'No' }
 
    if (currentKycData?.details?.financialInstitution == 'yes' && ListD.includes(currentKycData?.details?.incorporationCountry)) { //check List D
      emptyFormData.nonSingaporeFI  = 'Yes'
    } else { emptyFormData.nonSingaporeFI = 'No' }

    // qn7
    emptyFormData.politicallyExposedPerson = 'No' 
    currentKycData?.details?.connectedParty.forEach( party => { // .map produces a new array .forEach modify in place
      if (party?.pepAssociate == 'yes' || party?.pepPerson == 'yes' || party?.pepFamily == 'yes' ) {
        emptyFormData.politicallyExposedPerson = 'Yes'
      }   
    })

    // qn8
    emptyFormData.countryCountermeasureFATF = 'No' 
    if (ListB.includes(currentKycData?.details?.incorporationCountry)) {
       emptyFormData.countryCountermeasureFATF = 'Yes' 
    }
    currentKycData?.details?.connectedParty.forEach( party => {  
      if ( ListB.includes(party.addressCountry) || ListB.includes(party.nationality) ) {
        emptyFormData.countryCountermeasureFATF = 'Yes'
      }   
    })

    // qn9
    emptyFormData.countryInadequateAML_CFT = 'No' 
    if (ListC.includes(currentKycData?.details?.incorporationCountry)) {
       emptyFormData.countryInadequateAML_CFT = 'Yes' 
    }
    currentKycData?.details?.connectedParty.forEach( party => {  
      if ( ListC.includes(party.addressCountry) || ListC.includes(party.nationality) ) {
        emptyFormData.countryInadequateAML_CFT = 'Yes'
      }   
    })


    // qn 18 // natureOfBusiness dropDown of industry
    emptyFormData.higherRiskSector = 'No' 
    if (currentKycData?.details?.natureOfBusiness?.split(' - ')[0].includes('High')) {   // ?.split() apparantly can use it to stop methods too, not just properties
      emptyFormData.higherRiskSector = 'Yes' 
    }
 
    // q22 
    emptyFormData.highRiskJurisdictionFATF = 'No' 
    if (highRiskJurisdictions.includes(currentKycData?.details?.incorporationCountry)) {
       emptyFormData.highRiskJurisdictionFATF = 'Yes' 
    }
    currentKycData?.details?.connectedParty.forEach( party => {  
      if ( highRiskJurisdictions.includes(party.addressCountry) || highRiskJurisdictions.includes(party.nationality) ) {
        emptyFormData.highRiskJurisdictionFATF = 'Yes'
      }   
    })

 
    // q23
    emptyFormData.credibleBodyIdentifiedCountry = 'No' 
    if (transparencyInternational.includes(currentKycData?.details?.incorporationCountry)) {
       emptyFormData.credibleBodyIdentifiedCountry = 'Yes' 
    }
    currentKycData?.details?.connectedParty.forEach( party => {  
      if ( transparencyInternational.includes(party.addressCountry) || transparencyInternational.includes(party.nationality) ) {
        emptyFormData.credibleBodyIdentifiedCountry = 'Yes'
      }   
    })
 

    return emptyFormData
  }
}